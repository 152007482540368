/**
 * Kameleoon Goal Id  constants
 */
const KameleoonGoals = {
    AskQuestionClickedItemPage: 'askQuestionClickedItemPage',
    AuctionHouseClickedPriceResults: 'auctionHouseClickedPriceResults',
    BidPlaced: 'absenteeBidPlaced',
    BidPlacedItemPage: 'bidPlacedItemPage',
    BuyNowButtonPressed: 'buyNowButtonPressed',
    BuyNowHeaderLinkClicked: 'buyNowHeaderLinkClicked',
    BuyNowOfferSubmitted: 'buyNowOfferSubmitted',
    CategoryInNavClicked: 'categoryInNavClicked',
    CompleteAccountSetupClicked: 'completeAccountSetupClicked',
    FollowedPriceResultsSearch: 'followedPriceResultsSearch',
    ItemCardClicked: 'itemCardClicked',
    ItemCardClickedCatalogPage: 'itemCardClickedCatalogPage',
    ItemCardClickedPriceResults: 'itemCardClickedPriceResults',
    J52BidPlaced: 'j52BidPlaced',
    LearnMoreClickedPurchaseProtectionBlurb: 'learnMoreClickedPurchaseProtectionBlurb',
    MainItemClickedPriceResults: 'mainItemClickedPriceResults',
    MessagedSellerItemPage: 'messagedSellerItemPage',
    RegisterForAuction: 'registerForAuction',
    RegisterForAuctionItemPage: 'registerForAuctionItemPage',
    ReviewsModalViewedPriceResults: 'ReviewsModalViewedPriceResults',
    SavedItem: 'savedItem',
    SavedItemCatalogPage: 'savedItemCatalogPage',
    SavedItemCategoryPage: 'savedItemCategoryPage',
    SavedItemItemPage: 'savedItemItemPage',
    SavedPriceResultsItem: 'savedPriceResultsItem',
    SavedSearchItem: 'savedSearchItem',
    SaveIconOnLotDetailImageClicked: 'saveIconOnLotDetailImageClicked',
    SearchBarHouseClicked: 'searchBarHouseClicked',
    SearchBarSearchSubmitted: 'searchBarSearchSubmitted',
    SearchBarSuggestionClicked: 'searchBarSuggestionClicked',
    SearchResultsAdUnitClicked: 'searchResultsAdUnitClicked',
};

export type KameleoonGoal = {
    goalId: number;
    goalName: string;
};

/**
 * @field AskQuestionClickedItemPage - The Ask Question button was clicked on the item page
 * @field BidPlaced - A bid has been placed (absentee and live bids should both be counted)
 * @field BidPlacedItemPage - A bid has been placed from the item page (absentee/timed+ bids)
 * @field BuyNowButtonPressed - The Buy Now button was clicked from the item page
 * @field BuyNowHeaderLinkClicked - The Buy Now header link was clicked
 * @field BuyNowOfferSubmitted - The Buy Now offer that differs from sellers price was submitted
 * @field ItemCardClicked - The card, in search, has been clicked
 * @field ItemCardClickedCatalog - A item card was clicked from the catalog page
 * @field LearnMoreClickedPurchaseProtectionBlurb - The user has clicked the learn more link in the purchase protection blurb
 * @field MessagedSellerItemPage - A message to the seller has been sent from the item page
 * @field SavedItem - The item has been favorited
 * @field SavedItemCatalogPage - The item has been favorited from the catalog page
 * @field SavedItemItemPage - The item has been favorited from the item page
 * @field SavedSearchItem - The item has been favorited from the search page
 * @field RegisterAccount - The user has registered an account
 * @field RegisterForAuctionItemPage - The user has registered for an auction from the item page
 * @field SavedPriceResultsItem - The item has been favorited from the price results page
 * @field SavedSearchItem - The item has been favorited from the search page
 * @field FollowedPriceResultsSearch - The user has followed the price results from the search page
 * @field ItemCardClickedPriceResults - The card, in price results, has been clicked
 * @field MainItemClickedPriceResults - The main item, in price results, has been clicked
 * @field J52BidPlaced - A bid has been placed in the J52 Buyers Premium experiment
 * @field AuctionHouseClickedPriceResults - The auction house has been clicked in the price results page
 * @field ReviewsModalViewedPriceResults - The reviews modal has been viewed in the price results page
 * @field SearchBarHouseClicked - The search bar house has been clicked
 * @field SearchBarSuggestionClicked - The search bar suggestion has been clicked
 * @field SearchBarSearchSubmitted - The search bar search has been submitted
 * @field CategoryInNavClicked - The category in the navigation has been clicked
 * @field SearchResultsAdUnitClicked - The ad unit in the search results has been clicked
 * @field SavedItemCategoryPage - The item has been favorited from the category page
 * @field SaveIconOnLotDetailImageClicked - The save icon on the lot detail image has been clicked
 * @field CompleteAccountSetupClicked - The complete account setup button was clicked
 */
export const KameleoonGoalData: {
    [key in keyof typeof KameleoonGoals]: KameleoonGoal;
} = {
    AskQuestionClickedItemPage: { goalId: 355032, goalName: 'AskQuestionClickedItemPage' },
    AuctionHouseClickedPriceResults: { goalId: 361327, goalName: 'AuctionHouseClickedPriceResults' },
    BidPlaced: { goalId: 352421, goalName: 'BidPlaced' },
    BidPlacedItemPage: { goalId: 352904, goalName: 'BidPlacedItemPage' },
    BuyNowButtonPressed: { goalId: 359033, goalName: 'BuyNowButtonPressed' },
    BuyNowHeaderLinkClicked: { goalId: 361192, goalName: 'BuyNowHeaderLinkClicked' },
    BuyNowOfferSubmitted: { goalId: 359037, goalName: 'BuyNowOfferSubmitted' },
    CategoryInNavClicked: { goalId: 362560, goalName: 'CategoryInNavClicked' },
    CompleteAccountSetupClicked: { goalId: 370272, goalName: 'CompleteAccountSetupClicked' },
    FollowedPriceResultsSearch: { goalId: 356084, goalName: 'FollowedPriceResultsSearch' },
    ItemCardClicked: { goalId: 353263, goalName: 'ItemCardClicked' },
    ItemCardClickedCatalogPage: { goalId: 361323, goalName: 'ItemCardClickedCatalog' },
    ItemCardClickedPriceResults: { goalId: 356095, goalName: 'ItemCardClickedPriceResults' },
    J52BidPlaced: { goalId: 357726, goalName: 'J52BidPlaced' },
    LearnMoreClickedPurchaseProtectionBlurb: { goalId: 367454, goalName: 'LearnMoreClickedPurchaseProtectionBlurb' },
    MainItemClickedPriceResults: { goalId: 356096, goalName: 'MainItemClickedPriceResults' },
    MessagedSellerItemPage: { goalId: 353604, goalName: 'MessagedSellerItemPage' },
    RegisterForAuction: { goalId: 356085, goalName: 'RegisterForAuction' },
    RegisterForAuctionItemPage: { goalId: 353268, goalName: 'RegisterForAuctionItemPage' },
    ReviewsModalViewedPriceResults: { goalId: 361324, goalName: 'ReviewsModalViewedPriceResults' },
    SavedItem: { goalId: 352422, goalName: 'SavedItem' },
    SavedItemCatalogPage: { goalId: 358778, goalName: 'SavedItemCatalogPage' },
    SavedItemCategoryPage: { goalId: 369796, goalName: 'SavedItemCategoryPage' },
    SavedItemItemPage: { goalId: 353259, goalName: 'SavedItemItemPage' },
    SavedPriceResultsItem: { goalId: 356094, goalName: 'SavedPriceResultsItem' },
    SavedSearchItem: { goalId: 353262, goalName: 'SavedSearchItem' },
    SaveIconOnLotDetailImageClicked: { goalId: 371167, goalName: 'SaveIconOnLotDetailImageClicked' },
    SearchBarHouseClicked: { goalId: 361529, goalName: 'SearchBarHouseClicked' },
    SearchBarSearchSubmitted: { goalId: 361527, goalName: 'SearchBarSearchSubmitted' },
    SearchBarSuggestionClicked: { goalId: 361528, goalName: 'SearchBarSuggestionClicked' },
    SearchResultsAdUnitClicked: { goalId: 366540, goalName: 'SearchResultsAdUnitClicked' },
};

export type KameleoonGoalKeys = (typeof KameleoonGoals)[keyof typeof KameleoonGoals];
