export enum GetHelpModalTypes {
    ItemPage = 'itemPage',
    WonItemsPage = 'wonItemsPage',
}

export enum ButtonType {
    MessageHouse = 'messageHouse',
    MessageSupport = 'messageSupport',
}

export enum MessageRecipient {
    BidderSupport = 'BidderSupport',
    House = 'house',
    ShippingSupport = 'ShippingSupport',
}

export enum MessageType {
    Shipping = 'shipping',
    Support = 'support',
}
