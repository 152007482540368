export const pastFilterOptions = {
    ALL: 'ALL',
    LOST: 'LOST',
    WON: 'WON',
} as const;

export const upcomingFilterOptions = {
    ALL: 'ALL',
    LEADING: 'LEADING',
    OUTBID: 'OUTBID',
} as const;

export default { ...pastFilterOptions, ...upcomingFilterOptions };
