import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SHOW_BUTTON_THRESHOLD = 1250;

const BackToTop = () => {
    const { pathname } = useLocation();
    const checkoutPage = pathname.includes('/checkout/');
    const [showButton, setShowButton] = React.useState(false);

    const onScroll = React.useCallback(() => {
        if (window.scrollY >= SHOW_BUTTON_THRESHOLD && !showButton) {
            setShowButton(true);
        } else if (window.scrollY < SHOW_BUTTON_THRESHOLD && showButton) {
            setShowButton(false);
        }
    }, [showButton]);

    const handleClick = React.useCallback(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, []);

    React.useEffect(() => {
        if (window.scrollY) {
            onScroll();
        }
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);

    if (checkoutPage) {
        return null;
    }

    return (
        <StyledUpButton
            $showButton={showButton}
            onClick={handleClick}
        >
            <StyledArrowUpDiv />
            <StyledBackToTopDiv>
                <FormattedMessage id="back_to_top_button" />
            </StyledBackToTopDiv>
        </StyledUpButton>
    );
};

export default React.memo(BackToTop);

interface ButtonProps {
    $showButton: boolean;
}

const StyledUpButton = styled.div<ButtonProps>`
    background-color: ${({ theme }) => theme.colors.grey100};
    border-radius: 40px;
    height: 44px;
    width: 44px;
    position: fixed;
    bottom: 20px;
    cursor: pointer;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    opacity: 0;
    right: 20px;
    z-index: 15;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    align-items: center;
    justify-content: center;
    transition:
        visibility 0s,
        opacity 0.5s ease-in,
        right 0.5s ease-in;
    ${({ $showButton }) =>
        $showButton
            ? css`
                  visibility: visible;
                  opacity: 1;
              `
            : ''};

    @media print {
        display: none;
    }
`;

const StyledArrowUpDiv = styled.div`
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.colors.white};
    margin: 7px 0 5px;
`;

const StyledBackToTopDiv = styled.div`
    text-align: center;
    line-height: 13px;
    margin-bottom: 7px;
`;
