import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreviousLocation } from '@/components/PreviousLocation/PreviousLocation';

/**
 * Restores scroll position when returning to previously visited routes
 * as well as setting scroll position on new pages to (0,0)
 */

const ScrollMemory = () => {
    const location = useLocation();
    const prevLocation = usePreviousLocation();
    const historyScrollLocations = useRef(new Map<string, number>());

    useEffect(() => {
        /**
         * Logic tree goes like:
         * 1. If we are on a different route (page)
         *      - url could be the same but if we navigated through a route link it will have a different key)
         * 2. If we are on the discover page, do nothing
         *      - it has a custom pagination thing that we don't want to scroll to top if it's query params change
         * 3. If we have a stored scrollY position for the page, scroll to that position
         * 4. If it is a new unstored route, goto top of page
         */
        if (location.key !== prevLocation.key) {
            const previousScrollLocation = historyScrollLocations.current.get(location.key);
            const isDiscoverPage = location.pathname === '/c' || location.pathname === '/c/';
            const preventScrollReset = location.state?.preventScrollReset;

            window.history.replaceState({ ...location.state, filterAction: undefined }, '');

            if (isDiscoverPage || preventScrollReset) {
                return;
            } else if (previousScrollLocation) {
                window.scrollTo(0, previousScrollLocation);
            } else {
                window.scrollTo(0, 0);
            }
        }

        // constantly listening for scroll events
        const listener = () => {
            historyScrollLocations.current.set(location.key, window.scrollY);
        };
        window.addEventListener('scroll', listener, true);
        return () => {
            window.removeEventListener('scroll', listener, true);
        };
    }, [location, prevLocation]);

    return null;
};

export default ScrollMemory;
