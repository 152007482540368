import { BodyPrimary, H2, H5 } from '@liveauctioneers/hammer-ui-core/text';
import { dismissModal } from '@/redux/modules/modal';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { Modal } from '@liveauctioneers/hammer-ui-core/modal';
import { ShieldCheck } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { useAppDispatch } from '@/redux/hooks';
import { useCallback, useState } from 'react';

const messages = [
    'itemPage.purchaseProtection.modal.body.appraisalGuarantee',
    'itemPage.purchaseProtection.modal.body.authenticityGuarantee',
    'itemPage.purchaseProtection.modal.body.refundGuarantee',
];

const PurchaseProtectionModal = () => {
    const [showPurchaseProtectionModal, setShowPurchaseProtectionModal] = useState(true);
    const dispatch = useAppDispatch();

    const closeModal = useCallback(() => {
        setShowPurchaseProtectionModal(false);
        dispatch(dismissModal());
    }, [dispatch]);

    return (
        <Modal
            body={
                <section className="flex flex-col gap-sm py-sm">
                    <H5 data-testid="purchase-protection-modal-subheader">
                        <FormattedMessage id="itemPage.purchaseProtection.modal.subheader" />
                    </H5>
                    {messages.map((line, index) => (
                        <div
                            className="flex flex-row gap-2xs"
                            key={index}
                        >
                            <figure className="mt-3xs">
                                <ShieldCheck
                                    color="secondary"
                                    data-testid={`${line.split('.').at(-1)}-icon`}
                                    size="sm"
                                />
                            </figure>
                            <BodyPrimary data-testid={`${line.split('.').at(-1)}-msg`}>
                                <FormattedMessage
                                    id={line}
                                    values={{
                                        bold: (content) => (
                                            <span
                                                className="hui-text-h5"
                                                key="purchase-protection-modal-bold-text"
                                            >
                                                {content}
                                            </span>
                                        ),
                                    }}
                                />
                            </BodyPrimary>
                        </div>
                    ))}
                </section>
            }
            handleClose={closeModal}
            position="top"
            show={showPurchaseProtectionModal}
            title={
                <H2
                    className="mb-xs"
                    data-testid="purchase-protection-modal-title"
                >
                    <FormattedMessage id="itemPage.purchaseProtection.modal.title" />
                </H2>
            }
        />
    );
};

export default PurchaseProtectionModal;
