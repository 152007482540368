import { createContext, useContext, useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

export const PreviousLocationContext = createContext({
    pathname: '',
} as Location);

export const PreviousLocationProvider = ({ children }) => {
    const location = useLocation();
    const [route, setRoute] = useState({
        from: location,
        to: location,
    });

    useEffect(() => {
        if (location !== route.to) {
            setRoute((prev) => ({ from: prev.to, to: location }));
        }
    }, [location, route.to]);

    return <PreviousLocationContext.Provider value={route.from}>{children}</PreviousLocationContext.Provider>;
};

export const usePreviousLocation = () => {
    return useContext(PreviousLocationContext);
};
