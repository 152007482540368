import { Close } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { getBrowserMajorVersion, getBrowserName } from '@/redux/modules/browser';
import { getCookie, setCookie } from '@/redux/modules/cookies';
import { IconButton } from '@liveauctioneers/hammer-ui-core/iconButton';
import { InPageMessage } from '@liveauctioneers/caterwaul-components/lib/InPageMessage/InPageMessage';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import React from 'react';
import styled from 'styled-components';

const COOKIE_MAX_AGE = 86400 * 30; // 30 days
const COOKIE_NAME = 'dismissableOutdatedBrowserBanner';
const COOKIE_BODY = 'dismissed';

const BrowserOutdatedBanner = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const majorVersion = useAppSelector(getBrowserMajorVersion);
    const name = useAppSelector(getBrowserName);

    const hasDismissedCookie = useAppSelector((state) => getCookie(state, COOKIE_NAME)) === COOKIE_BODY;
    const handleSetCookie = React.useCallback((): void => {
        dispatch(
            setCookie({
                maxAge: COOKIE_MAX_AGE,
                name: COOKIE_NAME,
                value: COOKIE_BODY,
            })
        );
    }, [dispatch]);

    if (hasDismissedCookie) {
        return null;
    }

    return (
        <StyledInPageMessage
            className="browserIsOutdated"
            type="warning"
        >
            <StyledContentContainer>
                {formatMessage(
                    { id: 'did_you_know_that_your_web_browser' },
                    {
                        formalName: name,
                        majorVersion: majorVersion,
                    }
                )}
                <br />
                {formatMessage(
                    { id: 'browsers_for_best_experience' },
                    {
                        chrome: 'Google Chrome',
                        edge: 'Microsoft Edge',
                        firefox: 'Mozzila Firefox',
                        safari: 'Apple Safari',
                    }
                )}

                <IconButton
                    className="absolute right-0 top-0"
                    icon={Close}
                    onClick={handleSetCookie}
                    size="sm"
                    variant="transparent"
                />
            </StyledContentContainer>
        </StyledInPageMessage>
    );
};

export default BrowserOutdatedBanner;

const StyledInPageMessage = styled(InPageMessage)`
    justify-content: center;
    align-items: center;

    > div {
        flex-grow: 0;
    }
`;

const StyledContentContainer = styled.div`
    position: relative;
    padding-right: 32px;
`;
