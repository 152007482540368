import * as React from 'react';
import { Locales } from '@/redux/modules/intl.types';
import { IntlProvider as RIProvider } from '@liveauctioneers/hammer-ui-core/intl';
import en from '@/intl/en';

type Props = {
    children: React.ReactNode;
};

const IntlProvider = ({ children }: Props) => {
    return (
        <RIProvider
            key={Locales.en}
            locale={Locales.en}
            messages={en}
        >
            {children}
        </RIProvider>
    );
};

export default IntlProvider;
