import { KameleoonExperiments, KameleoonVariants } from '@/hooks/kameleoon/kameleoonExperiments';
import { useExperimentBase } from '@/hooks/kameleoon/utils/useExperimentBase';
import { useTrackExposedToExperiment } from '@/utils/analytics/experiments/trackExposedToExperiment.analytics';

const useIsInCompleteRegistrationPromptExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.CompleteRegistrationPrompt,
        KameleoonVariants.CompleteRegistrationPrompt.MyAccountIconBadge
    );

const useCompleteRegistrationPromptControl = () =>
    useExperimentBase(
        KameleoonExperiments.CompleteRegistrationPrompt,
        KameleoonVariants.CompleteRegistrationPrompt.Control
    );

const useIsNotInCompleteRegistrationPromptExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.CompleteRegistrationPrompt,
        KameleoonVariants.CompleteRegistrationPrompt.Off
    );

export const useCompleteRegistrationPromptExperiment = () => {
    const hasCompleteRegistrationPromptVariant = useIsInCompleteRegistrationPromptExperiment();
    const isInControl = useCompleteRegistrationPromptControl();
    useIsNotInCompleteRegistrationPromptExperiment();

    let variant = 'Off';
    if (hasCompleteRegistrationPromptVariant) {
        variant = 'My Account Icon Badge';
    } else if (isInControl) {
        variant = 'Control';
    }

    const trackExposure = useTrackExposedToExperiment('Complete Registration Prompt', variant);
    trackExposure();

    return hasCompleteRegistrationPromptVariant;
};
