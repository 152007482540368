import {
    getIsWhiteLabel,
    getWhiteLabelLogo,
    getWhiteLabelSiteName,
    getWhiteLabelSiteUrl,
} from '@/redux/modules/whiteLabel';
import { useAppSelector } from '@/redux/hooks';

export const useWhiteLabelProps = () => {
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const whiteLabelLogo = useAppSelector(getWhiteLabelLogo);
    const siteName = useAppSelector(getWhiteLabelSiteName);
    const siteUrl = useAppSelector(getWhiteLabelSiteUrl);

    if (isWhiteLabel) {
        return {
            whiteLabelData: {
                logo: whiteLabelLogo,
                siteName,
                siteUrl,
            },
        };
    }
};
