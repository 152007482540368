import { KameleoonExperiments, KameleoonVariants } from '@/hooks/kameleoon/kameleoonExperiments';
import { useExperimentBase } from '@/hooks/kameleoon/utils/useExperimentBase';
import { useTrackExposedToExperiment } from '@/utils/analytics/experiments/trackExposedToExperiment.analytics';

const useIsInNewUserSegmentExperiment = () =>
    useExperimentBase(KameleoonExperiments.NewUserSegment, KameleoonVariants.NewUserSegment.On);

const useHasNewUserSegmentControl = () =>
    useExperimentBase(KameleoonExperiments.NewUserSegment, KameleoonVariants.NewUserSegment.Control);

const useIsNotInNewUserSegmentExperiment = () =>
    useExperimentBase(KameleoonExperiments.NewUserSegment, KameleoonVariants.NewUserSegment.Off);

export const useNewUserSegmentExperiment = () => {
    const hasNewUserSegmentVariant = useIsInNewUserSegmentExperiment();
    const isInControl = useHasNewUserSegmentControl();
    useIsNotInNewUserSegmentExperiment();

    let variant = 'Off';
    if (hasNewUserSegmentVariant) {
        variant = 'On';
    } else if (isInControl) {
        variant = 'Control';
    }
    const trackExposure = useTrackExposedToExperiment('New User Segment', variant);
    trackExposure();

    return hasNewUserSegmentVariant;
};
