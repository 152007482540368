import * as React from 'react';
import { getClientHydrated } from '@/redux/modules/client';
import { openForgotPasswordModal } from '@/redux/modules/modal';
import { parseQueryParams, removeQueryParams, stringifyQuery } from '@/utils/queryParams';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

const ForgotPasswordAction = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams: any = React.useMemo(() => parseQueryParams(location.search), [location.search]);
    const dispatch = useAppDispatch();
    const isClientHydrated = useAppSelector(getClientHydrated);

    React.useEffect(() => {
        if (queryParams.action === 'forgot' && isClientHydrated) {
            const query = removeQueryParams(queryParams, ['action']);
            const stringified = stringifyQuery(query);
            const newLocation = location.pathname + stringified ? '?' + stringified : '';
            dispatch(openForgotPasswordModal());
            navigate(newLocation);
        }
    }, [dispatch, isClientHydrated, location, navigate, queryParams]);

    return null;
};

export default ForgotPasswordAction;
