import {
    getBrowserPermission,
    getPermission,
    requestBrowserPermission,
    setPermission,
} from '@/redux/modules/notification';
import { NotificationPermissions } from '@/enums/notificationPermissions.enum';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import ms from 'ms';

const cookie = new Cookies();
const COOKIE_MAX_AGE = ms('180d');
const COOKIE_NAME = 'enableNotificationPrompt';
const COOKIE_BODY = 'dismissed';

export const useBrowserAlertsProps = () => {
    const dispatch = useAppDispatch();
    const permission = useAppSelector(getPermission);
    const [dismissed, setDismissed] = useState(false);

    const enableBrowserAlerts = useCallback(() => {
        dispatch(requestBrowserPermission());
    }, [dispatch]);

    const disableBrowserAlerts = useCallback(() => {
        dispatch(setPermission(NotificationPermissions.Unset));
    }, [dispatch]);

    const dismissBrowserAlerts = useCallback(() => {
        cookie.set(COOKIE_NAME, COOKIE_BODY, { maxAge: COOKIE_MAX_AGE });
        setDismissed(true);
    }, []);

    const isDismissed = useCallback(() => {
        const hasBeenChecked = cookie.get(COOKIE_NAME);
        setDismissed(COOKIE_BODY === hasBeenChecked);
        return COOKIE_BODY === hasBeenChecked;
    }, []);

    useEffect(() => {
        setDismissed(isDismissed());
    }, [isDismissed]);

    useEffect(() => {
        dispatch(getBrowserPermission());
    }, [dispatch]);

    return {
        disable: disableBrowserAlerts,
        dismiss: dismissBrowserAlerts,
        dismissed: dismissed,
        enable: enableBrowserAlerts,
        status: permission,
    };
};
