/* Tailwind also imports theme/css/Liveauctioneers.css */
import '@/tailwind.css';
import '@/theme/theme.css';
import '@liveauctioneers/caterwaul-components/theme/theme.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import * as apiPerfLogger from '@/utils/apiPerformanceLogger';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { buildUrlFromLocation } from '@/utils/urls';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { getBasename } from '@/client/getBasename';
import { getInitialClientStore } from '@/redux/store';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import { grabKameleoonData } from '@/components/KameleoonProvider/grabKameleoonData';
import { HammerUIProvider } from '@liveauctioneers/hammer-ui-core/providers';
import { HelmetProvider } from 'react-helmet-async';
import { initializeWebKameleoonClient } from '@/components/KameleoonProvider/initializeClient.client';
import { isItemPage404 } from '@/ssr/utils/isItemPage404';
import { KameleoonClientProvider } from './KameleoonClientProvider';
import { loadableReady } from '@loadable/component';
import { logClientPerformanceStats } from '@/redux/modules/apiPerformanceStats';
import { mergedCaterwaulAndHammerUIThemes } from '@/theme/mergedCaterwaulAndHammerUIThemes';
import { PersistorConfig, persistStore } from 'redux-persist';
import { PreviousLocationProvider } from '@/components/PreviousLocation/PreviousLocation';
import App from '@/pages/App/App';
import AppRoutes, { routes as getRoutes } from '@/routes';
import ErrorBoundary from '@liveauctioneers/caterwaul-components/lib/ErrorBoundary/ErrorBoundary';
import NoCookies from './NoCookies';
import Provider from '@/components/IntlRedux/Provider';
import ReactModal from 'react-modal';
import RouteDataLoader from './RouteDataLoader';

const localStoragePersistConfig: PersistorConfig = {
    debounce: 33,
    keyPrefix: 'la:',
    whitelist: ['forgotPassword', 'persist', 'recentItem', 'walkthroughs'],
};

const helmetContext = {};

const localStorageCheck = () => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};

// Create the redux store and routing history
const store = getInitialClientStore();

if (typeof window !== 'undefined') {
    // @ts-expect-error Kameleoon wants this, so let's add it.
    window.kameleoonQueue = window.kameleoonQueue || [];
}

// initializes client-side kameleoon SDK for a/b tests
initializeWebKameleoonClient({
    bidderId: store.getState().user.userData.bidderId,
    environment: store.getState().config.kameleoonEnvironment,
    siteCode: store.getState().config.kameleoonSiteCode,
}).then((kameleoonClient) => {
    /**
     * Wait for all loadable components to be loaded
     * @see https://loadable-components.com/docs/api-loadable-component/#loadableready
     */
    loadableReady(() => {
        /**
         * Render the client-side app
         */

        const dest = document.getElementById('content');
        const basename = getBasename();

        ReactModal.setAppElement('#content');

        const isWhiteLabel = getIsWhiteLabel(store.getState());
        const bidderId = store.getState().user.userData.bidderId;
        const visitorCode = kameleoonClient?.getVisitorCode();

        const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });

        // Get feature flag variants for conditional routes
        const { featureFlagVariants } = grabKameleoonData(kameleoonClient, visitorCodeOrBidderId);

        // Get application routes
        const routes = getRoutes({ featureFlagVariants, isWhiteLabel });

        // sanitize and redirect pathname if it contains %0d or %0a (crlf injection)
        if (
            window?.location?.pathname.toLowerCase().includes('%0d') ||
            window?.location?.pathname.toLowerCase().includes('%0a')
        ) {
            const pathname = window.location.pathname.replace(/(.*?)%0[dD]|%0[aA].*/gm, '$1');
            const location = {
                hash: window.location?.hash,
                pathname: pathname,
                search: window.location?.search,
            };
            const redirect = buildUrlFromLocation(location, window.location?.origin);
            window.location.assign(redirect);
            return;
        }

        // record api performance metrics
        const apiMetrics = apiPerfLogger.logger(performance);

        // save to local storage
        persistStore(store, localStoragePersistConfig);

        if (!localStorageCheck() && dest) {
            const root = createRoot(dest!);
            return root.render(<NoCookies />);
        }

        // handle item-type pages that do not redirect to not-found,
        // but instead leave URL the same and return status 404
        const is404ItemPage = isItemPage404(window?.location?.pathname, store.getState());

        // compute performance stats from recorded metrics
        setTimeout(() => {
            const computedApiStats = apiPerfLogger.getStats(apiMetrics);
            store.dispatch(logClientPerformanceStats(computedApiStats, window.location.href));
        }, 5000);

        const TheApp = () => (
            <HammerUIProvider theme={mergedCaterwaulAndHammerUIThemes}>
                <ErrorBoundary>
                    <HelmetProvider context={helmetContext}>
                        <Provider
                            key="provider"
                            store={store}
                        >
                            <KameleoonClientProvider kameleoonClient={kameleoonClient}>
                                <BrowserRouter basename={basename}>
                                    <PreviousLocationProvider>
                                        <App>
                                            <RouteDataLoader
                                                routes={routes}
                                                store={store}
                                            >
                                                <AppRoutes
                                                    featureFlagVariants={featureFlagVariants}
                                                    is404ItemPage={is404ItemPage}
                                                    whiteLabel={isWhiteLabel}
                                                />
                                            </RouteDataLoader>
                                        </App>
                                    </PreviousLocationProvider>
                                </BrowserRouter>
                            </KameleoonClientProvider>
                        </Provider>
                    </HelmetProvider>
                </ErrorBoundary>
            </HammerUIProvider>
        );

        hydrateRoot(dest, <TheApp />);

        if (process.env.NODE_ENV !== 'production') {
            window.React = React; // enable debugger
        }
    });
});
