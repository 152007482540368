import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { getAllCatalogAds } from '@/redux/modules/catalog/ad/catalogAd.selectors';
import { trackAdUnitClick, trackAdUnitVisible } from '@/redux/modules/analytics';
import { TrackAdUnitProps } from '@liveauctioneers/hammer-ui-liveauctioneers/types';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';

export const useCategoryNavigationAdsProps = () => {
    const dispatch = useAppDispatch();
    const categoryAds = useAppSelector(getAllCatalogAds);

    const loadCatalog = useCallback(
        (catalogId: number) => dispatch(fetchCatalogsIfNeeded({ catalogIds: [catalogId] })),
        [dispatch]
    );

    const trackAdClick = useCallback(
        ({ adUnitId, catalogId, sellerId }: TrackAdUnitProps) => {
            dispatch(
                trackAdUnitVisible({
                    adUnitId,
                    catalogId,
                    consignment: false,
                    page: 'navigation',
                    sellerId,
                })
            );
        },
        [dispatch]
    );

    const trackAdVisible = useCallback(
        ({ adUnitId, catalogId, sellerId }: TrackAdUnitProps) => {
            dispatch(
                trackAdUnitClick({
                    adUnitId,
                    catalogId,
                    consignment: false,
                    page: 'navigation',
                    sellerId,
                })
            );
        },
        [dispatch]
    );

    return {
        adUnits: categoryAds,
        loadCatalog,
        trackAdUnitClick: trackAdVisible,
        trackAdUnitVisible: trackAdClick,
    };
};
