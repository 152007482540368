import * as React from 'react';
import { DefaultQueryParams, GenericQueryParams } from '@liveauctioneers/caterwaul-components/types/QueryParams';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

export enum HistoryMethod {
    'PUSH',
    'REPLACE',
}
export enum ModifyQueryParamsAction {
    'REMOVE',
    'REPLACE',
    'REPLACEFUNC',
    'ADD',
}

type BaseAction = {
    action: ModifyQueryParamsAction;
    method?: HistoryMethod;
    pathname?: string;
};

export interface RemoveAction extends BaseAction {
    action: ModifyQueryParamsAction.REMOVE;
    params: string | string[];
}

export interface ReplaceAction<QueryParams> extends BaseAction {
    action: ModifyQueryParamsAction.REPLACE;
    query: QueryParams;
}

export interface ReplaceFuncAction<QueryParams> extends BaseAction {
    action: ModifyQueryParamsAction.REPLACEFUNC;
    func: (currentParams: QueryParams | any) => QueryParams;
}

export interface AddAction<QueryParams> extends BaseAction {
    action: ModifyQueryParamsAction.ADD;
    query: QueryParams;
}

export type Options<QueryParams> =
    | RemoveAction
    | ReplaceAction<QueryParams>
    | ReplaceFuncAction<QueryParams>
    | AddAction<QueryParams>;

export const useModifyQueryParams = <QueryParams extends GenericQueryParams = DefaultQueryParams>() => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = search.substring(1);

    const currentParams = React.useMemo(() => qs.parse(searchParams), [searchParams]);

    return React.useCallback(
        (options: Options<QueryParams>) => {
            let isReplace = false;
            let params: any;

            if (options.method === HistoryMethod.REPLACE) {
                isReplace = true;
            }

            switch (options.action) {
                case ModifyQueryParamsAction.ADD:
                    params = {
                        ...currentParams,
                        ...options.query,
                    };
                    break;
                case ModifyQueryParamsAction.REPLACE:
                    params = {
                        ...options.query,
                    };
                    break;
                case ModifyQueryParamsAction.REPLACEFUNC:
                    params = options.func({ ...currentParams });
                    break;
                case ModifyQueryParamsAction.REMOVE:
                    params = {
                        ...currentParams,
                    };

                    if (Array.isArray(options.params)) {
                        options.params.forEach((param) => {
                            delete params[param];
                        });
                    } else {
                        delete params[options.params];
                    }
                    break;
                default:
                    return;
            }

            navigate(
                {
                    pathname: options.pathname,
                    search: qs.stringify(params),
                },
                isReplace ? { replace: true } : undefined
            );
        },
        [currentParams, navigate]
    );
};
