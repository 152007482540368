import { getInitialWidth } from '@/redux/modules/windowSize';
import { useAppSelector } from '@/redux/hooks';
import useIsNarrowerThan from '@liveauctioneers/caterwaul-components/lib/hooks/useIsNarrowerThan';

const useIsNarrowerThanCustom: typeof useIsNarrowerThan = (width, initialSSRWidth) => {
    const initialWidth = useAppSelector(getInitialWidth);

    return useIsNarrowerThan(width, initialSSRWidth || initialWidth);
};

export default useIsNarrowerThanCustom;
