import * as React from 'react';
import { BodyPrimary } from '@liveauctioneers/caterwaul-components/lib/Text/Text/TextComponents';
import { PerformanceLog } from '@/redux/modules/apiPerformanceStats';
import styled from 'styled-components';

type ApiStatsSummaryProps = {
    apiLog: PerformanceLog | null;
};

const ApiStatsSummary = ({ apiLog }: ApiStatsSummaryProps) => {
    if (!apiLog) {
        return null;
    }

    return (
        <SummaryContainer>
            <BodyPrimary>url: {apiLog.url}</BodyPrimary>
            <BodyPrimary>requests: {apiLog.stats.numRequests}</BodyPrimary>
            <BodyPrimary>waterfalls: {apiLog.stats.numWaterfalls}</BodyPrimary>
            <BodyPrimary>total time: {Math.round(apiLog.stats.totalFetchTime)}ms</BodyPrimary>
        </SummaryContainer>
    );
};

export default ApiStatsSummary;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border: 1px solid ${({ theme }) => theme.colors.black};
`;
