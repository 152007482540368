import {
    CustomData,
    Device,
    DeviceType,
    FeatureFlagType,
    KameleoonClient,
    UniqueIdentifier,
} from '@kameleoon/javascript-sdk';
import { ExperimentVariants } from '@/redux/modules/kameleoon/kameleoon.types';
import { fetchActiveExperimentVariants, storeVisitorCode } from '@/redux/modules/kameleoon/kameleoon.actions';
import { getBidderId, getUserBidWeightPercentile } from '@/redux/modules/account/user/user.selectors';
import { getMobileBrowserOS, isUiTablet } from '@/redux/modules/browser';
import { getUserSelectedVariants } from '@/redux/modules/kameleoon/kameleoon.selectors';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import { grabKameleoonData } from '@/components/KameleoonProvider/grabKameleoonData';
import { KameleoonCustomDataIndex } from '@/types/Kameleoon';
import { KameleoonProvider } from '@/components/KameleoonProvider/Context';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';

// Memo so that we don't ping the Kameleoon API on every render
export const KameleoonClientProvider = memo(
    ({ children, kameleoonClient }: { children: ReactNode; kameleoonClient: KameleoonClient }) => {
        const dispatch = useAppDispatch();

        const bidderId = useAppSelector(getBidderId);
        const bidWeightPercentile = useAppSelector(getUserBidWeightPercentile);
        const mobileBrowser = useAppSelector(getMobileBrowserOS);
        const isTablet = useAppSelector(isUiTablet);
        const visitorCode = kameleoonClient?.getVisitorCode();
        const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });
        const isRunningUpdate = useRef(false);

        const { featureFlags: initialFeatureFlags } = grabKameleoonData(kameleoonClient, visitorCodeOrBidderId);
        const userSelectedVariants = useAppSelector(getUserSelectedVariants);
        const [featureFlags, setFeatureFlags] = useState<FeatureFlagType[]>(initialFeatureFlags);
        const [featureFlagVariants, setFeatureFlagVariants] = useState<ExperimentVariants>(userSelectedVariants);

        dispatch(storeVisitorCode(visitorCode));

        useEffect(() => {
            const updateKameleoonData = async () => {
                // prevent multiple updates from running at the same time
                if (isRunningUpdate.current) {
                    return;
                }
                isRunningUpdate.current = true;

                if (bidderId > 0 && kameleoonClient && visitorCode) {
                    // First link visitor code to bidder id
                    const bidderIdentifierData = new CustomData(KameleoonCustomDataIndex.BidderId, String(bidderId));
                    kameleoonClient?.addData(visitorCode, bidderIdentifierData);

                    // Then link bidder id to unique identifier
                    const isUniqueIdentifier = bidderId > 0;
                    const uniqueIdentifier = new UniqueIdentifier(isUniqueIdentifier);
                    kameleoonClient.addData(visitorCodeOrBidderId, uniqueIdentifier);

                    // Add bid weight percentile to kameleoon
                    const bidWeightPercentileData = new CustomData(
                        KameleoonCustomDataIndex.BidWeightPercentile,
                        String(bidWeightPercentile)
                    );

                    kameleoonClient?.addData(visitorCodeOrBidderId, bidWeightPercentileData);

                    // Flush the data to kameleoon
                    kameleoonClient?.flush(visitorCodeOrBidderId);

                    // Get the remote visitor data for cross device syncing
                    await kameleoonClient.getRemoteVisitorData({
                        shouldAddData: true,
                        visitorCode: String(bidderId),
                    });
                }

                if (kameleoonClient && visitorCode) {
                    // Add device type to kameleoon
                    const deviceType = !mobileBrowser
                        ? DeviceType.Desktop
                        : isTablet
                          ? DeviceType.Tablet
                          : DeviceType.Phone;
                    kameleoonClient?.addData(visitorCodeOrBidderId, new Device(deviceType));

                    kameleoonClient?.flush(visitorCodeOrBidderId);
                }

                const { featureFlags: updatedFlags, featureFlagVariants: updatedVariants } = grabKameleoonData(
                    kameleoonClient,
                    // Use bidderId for getting feature flags if user is logged in
                    visitorCodeOrBidderId
                );
                setFeatureFlags(updatedFlags);
                // @ts-expect-error - type is slightly off since we're forcing some variants for testing
                setFeatureFlagVariants(updatedVariants);
                // @ts-expect-error - type is slightly off since we're forcing some variants for testing
                dispatch(fetchActiveExperimentVariants(updatedVariants));

                // reset ref to allow next update to run
                isRunningUpdate.current = false;
            };

            updateKameleoonData();
        }, [
            bidWeightPercentile,
            bidderId,
            dispatch,
            isTablet,
            kameleoonClient,
            mobileBrowser,
            visitorCode,
            visitorCodeOrBidderId,
        ]);

        return (
            <KameleoonProvider
                featureFlags={featureFlags}
                featureFlagVariants={featureFlagVariants}
                kameleoonClient={kameleoonClient}
                visitorCode={visitorCode}
            >
                {children}
            </KameleoonProvider>
        );
    }
);
