import { KameleoonExperimentIds, KameleoonExperimentsVariantIds } from '@/hooks/kameleoon/kameleoonExperiments';
import { useEffect } from 'react';
import { useKameleoonFeatureFlag } from '@/hooks/kameleoon/useKameleoon';

/**
 * Do not use this function directly! Use the corresponding experiment's hooks.
 */
export const useExperimentBase = (
    featureFlagKey: KameleoonExperimentIds,
    featureFlagVariant: KameleoonExperimentsVariantIds[typeof featureFlagKey]
) => {
    const { isShowingFeature, trackFeatureFlag } = useKameleoonFeatureFlag(featureFlagKey, featureFlagVariant);

    useEffect(() => {
        // only start tracking if we're showing the feature to the user and we're not already tracking
        if (isShowingFeature) {
            trackFeatureFlag();
        }
    }, [featureFlagKey, featureFlagVariant, isShowingFeature, trackFeatureFlag]);

    return isShowingFeature;
};
