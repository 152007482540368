import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import IntlProvider from './IntlProvider';

type Props = {
    children: React.ReactNode;
    store: any;
};
const Provider = ({ children, store }: Props) => (
    <ReduxProvider
        /**
         * removes warning in dev mode that logs the entire store multiple times per render
         * @see https://github.com/reduxjs/redux/discussions/4670
         */
        stabilityCheck="never"
        store={store}
    >
        <IntlProvider>{children}</IntlProvider>
    </ReduxProvider>
);

export default Provider;
