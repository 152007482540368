/**
 * append kameleoon engine tracking code to <body /> on every track call for microsoft clarity
 * as advised by kameleoon devs
 */
export const appendEngineTrackingCodeScript = (trackingCode: string): void => {
    const currentScript = document.getElementById('kameleoon-engine-tracking') as HTMLScriptElement;

    // remove the current script if it exists
    if (currentScript) {
        document.body.removeChild(currentScript);
    }

    // create a new script and append it to the body so the code in innerHTML is executed
    const script = document.createElement('script');
    script.id = 'kameleoon-engine-tracking';
    script.innerHTML = trackingCode;
    script.type = 'text/javascript';
    document.body.appendChild(script);
};
