import { KameleoonGoalData } from '@/hooks/kameleoon/kameleoonGoals';
import { useTrackConversion } from '@/hooks/kameleoon/useTrackConversion';

export const useTrackHeaderSearchSubmitted = () =>
    useTrackConversion({ goal: KameleoonGoalData.SearchBarSearchSubmitted });

export const useTrackHeaderSearchSuggestionClicked = () =>
    useTrackConversion({ goal: KameleoonGoalData.SearchBarSuggestionClicked });

export const useTrackHeaderSearchHouseClicked = () =>
    useTrackConversion({ goal: KameleoonGoalData.SearchBarHouseClicked });
