import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getApiPerformanceStats } from '@/redux/modules/apiPerformanceStats';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { useAppSelector } from '@/redux/hooks';
import ApiStatsSummary from './components/ApiStatsSummary/ApiStatsSummary';
import ApiStatsTable from './components/ApiStatsTable/ApiStatsTable';
import styled from 'styled-components';

type Props = {
    className?: string;
};

const ApiPerformanceStats = ({ className }: Props) => {
    const { client, server } = useAppSelector(getApiPerformanceStats);

    return (
        <div className={className}>
            <H2>
                <FormattedMessage id="healthcheck.sectionTitle.server" />
            </H2>
            <ApiStatsSummary apiLog={server} />
            <StyledApiStatsTable
                client={client}
                server={server}
                type="server"
            />
            <H2>
                <FormattedMessage id="healthcheck.sectionTitle.client" />
            </H2>
            <ApiStatsSummary apiLog={client} />
            <ApiStatsTable
                client={client}
                server={server}
                type="client"
            />
        </div>
    );
};

export default ApiPerformanceStats;

const StyledApiStatsTable = styled(ApiStatsTable)`
    margin-bottom: 64px;
`;
