import {
    CustomData,
    Environment,
    KameleoonClient,
    SDKConfigurationType,
    UniqueIdentifier,
    UserAgent,
} from '@kameleoon/javascript-sdk';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import { KameleoonCustomDataIndex } from '@/types/Kameleoon';

const PROD_UPDATE_TIME = 20;
const DEV_UPDATE_TIME = 5;

let client: KameleoonClient | null = null;

type KameleoonInitializationParams = {
    bidderId: number;
    environment: Environment;
    siteCode: string;
};

export const initializeWebKameleoonClient = async ({
    bidderId,
    environment,
    siteCode,
}: KameleoonInitializationParams): Promise<KameleoonClient> => {
    if (process.env.NODE_ENV === 'test') {
        throw new Error('Kameleoon client should not be initialized in test environment');
    }

    if (client) {
        return client;
    }

    let configuration: Partial<SDKConfigurationType> = {
        environment,
        networkDomain: 'script-atg.com',
        // on prod envs variants will be cached for 20 minutes
        // on non-prod envs have the kameleoon client update once every 5 minutes
        updateInterval: environment === 'production' ? PROD_UPDATE_TIME : DEV_UPDATE_TIME,
    };

    try {
        // save client ref if successfully initialized
        const newClient = new KameleoonClient({
            configuration,
            siteCode,
        });
        await newClient.initialize();
        client = newClient;

        const visitorCode = client?.getVisitorCode();
        const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });

        if (bidderId > 0) {
            // First link visitor code to bidder id
            const bidderIdentifierData = new CustomData(KameleoonCustomDataIndex.BidderId, String(bidderId));
            client.addData(visitorCode, bidderIdentifierData);

            // Then link bidder id to unique identifier
            const isUniqueIdentifier = bidderId > 0;
            const uniqueIdentifier = new UniqueIdentifier(isUniqueIdentifier);
            client.addData(String(bidderId), uniqueIdentifier);

            // Flush the data to kameleoon
            client.flush(visitorCodeOrBidderId);

            // Get the remote visitor data for cross device syncing
            await client.getRemoteVisitorData({
                shouldAddData: true,
                // We need to use the bidder id as the visitor code, so it can sync across devices.
                visitorCode: String(bidderId),
            });
        }

        // Add user agent to kameleoon
        const userAgent = new UserAgent(window.navigator.userAgent);
        client.addData(visitorCodeOrBidderId, userAgent);
    } catch (e) {
        console.error('Kameleoon failed to initialize', e);
    }

    return client;
};
