import * as React from 'react';
import { getBrowserDataFromUA } from '@/redux/modules/browser';
import { openClearCacheModal } from '@/redux/modules/modal';
import { useAppDispatch } from '@/redux/hooks';
import semverCmp from 'semver-compare';

export const versionOrGreater = (version, minVersion) => {
    if (!Boolean(version) || !Boolean(minVersion)) {
        return false;
    }

    const cmp = semverCmp(version, minVersion);

    return cmp === 0 || cmp === 1;
};

export const showClearCacheModal = (majorVersion, name, osVersion) => {
    if (!Boolean(name)) {
        return false;
    }

    // Safari's UA string reports the wrong OS version, this is a known bug.
    const isBigSurSafari14 = name === 'safari' && majorVersion === 14 && versionOrGreater(osVersion, '10.15.6');

    return isBigSurSafari14;
};

// localStorge Key
const LSKEY = 'safariCacheClearModalSeen';

const NewSafariSentinel = () => {
    const dispatch = useAppDispatch();

    const [browser, setBrowser] = React.useState({
        majorVersion: 0,
        name: '',
        osVersion: '',
    });
    const [touchPoints, setTouchPoints] = React.useState(undefined);

    const { majorVersion, name, osVersion } = browser;

    React.useEffect(() => {
        const userAgent = navigator.userAgent || '';
        const browser = getBrowserDataFromUA(userAgent);
        setBrowser(browser);
    }, []);

    // This is used to distinguish between iOS on iPad and macOS
    React.useEffect(() => {
        const maxTouchPoints = navigator.maxTouchPoints;
        setTouchPoints(maxTouchPoints);
    }, [touchPoints]);

    React.useEffect(() => {
        let seen = true;
        if (typeof window !== 'undefined') {
            seen = window.localStorage.getItem(LSKEY) === 'true';
        }

        if (showClearCacheModal(majorVersion, name, osVersion) && !seen && touchPoints === 0) {
            dispatch(openClearCacheModal());

            window.localStorage[LSKEY] = true;
        }
    }, [dispatch, majorVersion, name, osVersion, touchPoints]);

    return null;
};

export default React.memo(NewSafariSentinel);
