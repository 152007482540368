import { AccountHolderWalkthroughSteps, NewAccountWalkthroughSteps } from '@/types/walkthroughs';
import { CompleteAccountModalContext, openCompleteAccountSignUpModal, openLoginModal } from '@/redux/modules/modal';
import { en } from '@liveauctioneers/hammer-ui-liveauctioneers/intl/en';
import { EventContext } from '@/redux/modules/analytics';
import {
    getBidderMobileVerified,
    getDefaultAddress,
    getDefaultAddressIsVerified,
    isUserAtLeastPartiallyRegistered,
    isUserLoggedIn,
} from '@/redux/modules/account/user/user.selectors';
import { getItemIdFromPath } from '@/utils/urls';
import { getMyBidItemIds } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.selectors';
import { getTotalWonItemsCount } from '@/redux/modules/wonItems/wonItems.selectors';
import { getUnreadInboxCount } from '@/redux/modules/conversation/folders/conversationFolders.selectors';
import { Header } from '@liveauctioneers/hammer-ui-liveauctioneers/header';
import { HeaderExperiment } from '@liveauctioneers/hammer-ui-liveauctioneers/headerExperiment';
import { laPaymentDefaultCardSelector } from '@/redux/modules/creditCard';
import { logOutBidder } from '@/redux/modules/account/logout/logout.actions';
import { onboardingWalkthroughCurrentStep } from '@/redux/modules/walkthroughs/walkthroughs.selectors';
import { PaymentProviders } from '@/enums';
import {
    setOnboardingWalkthroughOpenProperty,
    setOnboardingWalkthroughStep,
} from '@/redux/modules/walkthroughs/walkthroughs.reducer';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useBrowserAlertsProps } from './hooks/useBrowserAlertsProps';
import { useCallback } from 'react';
import { useCategoryNavigationAdsProps } from './hooks/useCategoryNavigationAdsProps';
import { useCompleteRegistrationPromptExperiment } from '@/hooks/kameleoon/CompleteRegistrationPrompt/useCompleteRegistrationPrompt';
import { useLocation } from 'react-router-dom';
import { useNewUserSegmentExperiment } from '@/hooks/kameleoon/newUserSegment/useNewUserSegmentExperiment';
import { useNotificationDataProps } from './hooks/useNotificationDataProps';
import { useSearchProps } from './hooks/useSearchProps';
import { useSimplifiedNavExperiment } from '@/hooks/kameleoon/useSimplifiedNavExperiment/useSimplifiedNavExperiment';
import { useTrackCategoryInNavClicked } from '@/hooks/kameleoon/tracking/useTrackCategoryInNavClicked';
import {
    useTrackCompleteAccountCTAClicked,
    useTrackHeaderCategoryClickedAnalytics,
    useTrackMyAccountIconClicked,
} from '@/utils/analytics';
import { useTrackCompleteAccountSetupClicked } from '@/hooks/kameleoon/tracking/useTrackCompleteAccountSetup';
import { useWhiteLabelProps } from './hooks/useWhiteLabelProps';

export const HammerHeader = () => {
    const dispatch = useAppDispatch();
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const currentStep = useAppSelector(onboardingWalkthroughCurrentStep);
    const unreadCount = useAppSelector(getUnreadInboxCount);
    const wonItemsCount = useAppSelector(getTotalWonItemsCount);
    const { upcomingBidItemIds = [] } = useAppSelector(getMyBidItemIds);
    const upcomingBidsCount = upcomingBidItemIds.length;
    const userPartiallyRegistered = useAppSelector(isUserAtLeastPartiallyRegistered);
    const addressVerified = useAppSelector(getDefaultAddressIsVerified);
    const defaultAddress = useAppSelector(getDefaultAddress);
    const defaultCreditCard = useAppSelector((state) => laPaymentDefaultCardSelector(state, PaymentProviders.Payrix));
    const mobileVerified = useAppSelector(getBidderMobileVerified);
    const trackCompleteAccountCTAClicked = useTrackCompleteAccountCTAClicked();
    const hasCompleteRegistrationPromptVariant = useCompleteRegistrationPromptExperiment();
    const trackMyAccountIconClicked = useTrackMyAccountIconClicked();
    const trackCompleteAccountSetupClicked = useTrackCompleteAccountSetupClicked();
    const { pathname } = useLocation();
    const itemId = getItemIdFromPath(pathname);

    const trackHeaderCategoryClicked = useTrackHeaderCategoryClickedAnalytics();
    const trackCategoryInNavClickedConversion = useTrackCategoryInNavClicked();
    const { hasSimplifiedNavHighContrastVariant, hasSimplifiedNavWithBorderVariant } = useSimplifiedNavExperiment();

    useNewUserSegmentExperiment();

    const logout = useCallback(() => dispatch(logOutBidder()), [dispatch]);

    const showCompleteAccount =
        userPartiallyRegistered &&
        !(
            Boolean(defaultAddress?.firstName) &&
            Boolean(defaultAddress?.lastName) &&
            Boolean(defaultAddress?.address1) &&
            addressVerified &&
            Boolean(defaultCreditCard?.id) &&
            mobileVerified
        );

    const handleSetupLink = () => {
        dispatch(openCompleteAccountSignUpModal());
        trackCompleteAccountCTAClicked();
        trackCompleteAccountSetupClicked();
    };

    const login = useCallback(
        () =>
            dispatch(
                openLoginModal({
                    eventContext: EventContext.join,
                    id: itemId,
                    itemId,
                    type: CompleteAccountModalContext.Login,
                })
            ),
        [dispatch, itemId]
    );

    const signUp = useCallback(() => {
        dispatch(
            openCompleteAccountSignUpModal({
                eventContext: EventContext.join,
                id: itemId,
                itemId,
                type: CompleteAccountModalContext.Join,
            })
        );
        if (currentStep === AccountHolderWalkthroughSteps.Returning) {
            dispatch(setOnboardingWalkthroughOpenProperty(false));
            window.setTimeout(() => {
                dispatch(setOnboardingWalkthroughStep(NewAccountWalkthroughSteps.FirstTimeAnyPage));
            }, 400);
        }
    }, [currentStep, dispatch, itemId]);

    const onTrackCategoryClick = useCallback(
        (categoryName: string) => {
            trackHeaderCategoryClicked({ categoryName });
            trackCategoryInNavClickedConversion();
        },
        [trackHeaderCategoryClicked, trackCategoryInNavClickedConversion]
    );

    const props = {
        browserAlerts: useBrowserAlertsProps(),
        categoryNavigationAds: useCategoryNavigationAdsProps(),
        experiments: {
            isCompleteProfileCTAEnabled: false,
        },
        isUserLoggedIn: userLoggedIn,
        login,
        logout,
        notificationData: useNotificationDataProps(),
        onCompleteProfileCTAClicked: () => {},
        onMyProfileClicked: () => {},
        search: useSearchProps(),
        signUp,
        trackCategoryClick: onTrackCategoryClick,
        unreadCount,
        upcomingBidsCount,
        wonItemsCount,
        ...useWhiteLabelProps(),
        intl: en,
        zIndexes: {
            megaNavDropdown: 12,
            searchDropdown: 12,
        },
    };

    return hasSimplifiedNavHighContrastVariant || hasSimplifiedNavWithBorderVariant ? (
        <HeaderExperiment
            {...props}
            experiments={{
                ...props.experiments,
                isHighContrastHeader: hasSimplifiedNavHighContrastVariant,
                isProminentSearchInput: hasSimplifiedNavWithBorderVariant,
            }}
            onLoginClicked={login}
            onLogoutClicked={logout}
            onSignUpClicked={signUp}
        />
    ) : (
        <Header
            {...props}
            experiments={{
                isCompleteProfileCTAEnabled: showCompleteAccount && hasCompleteRegistrationPromptVariant,
            }}
            onCompleteProfileCTAClicked={handleSetupLink}
            onMyProfileClicked={trackMyAccountIconClicked}
        />
    );
};
