import * as React from 'react';
import { BodyPrimary, H2 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { getEntireReduxStore } from '@/redux/modules/apiPerformanceStats';
import { useAppDispatch } from '@/redux/hooks';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';

/**
 * @see https://zwbetz.com/get-the-approximate-size-of-a-js-object-in-bytes/
 */
const getSizeInKbs = (obj: object): number => {
    const str = JSON.stringify(obj);

    // Get the length of the Uint8Array
    const bytes = new TextEncoder().encode(str).length / 1024;

    return Number(bytes.toFixed(2));
};

type ReduxStoreSliceSize = {
    name: string;
    size: number;
};

const getStoreSliceSizes = (store: object): ReduxStoreSliceSize[] => {
    const sliceSizes = Object.keys(store).map((slice) => ({
        name: slice,
        size: getSizeInKbs(store[slice]),
    }));

    return sliceSizes;
};

/**
 * Measures the size of the redux store and its individual reducers in a nice sorted list
 *
 * This tool is useful for finding dupe or garbage data bloating the redux store
 */
const ReduxStoreSizeStats = () => {
    const dispatch = useAppDispatch();
    const [totalStoreSize, setTotalStoreSize] = React.useState(0);
    const [sliceSizes, setSliceSizes] = React.useState<ReduxStoreSliceSize[]>([]);

    const measureStore = React.useCallback(() => {
        const store = dispatch(getEntireReduxStore());
        setTotalStoreSize(getSizeInKbs(store));

        const storeSliceSizes = getStoreSliceSizes(store);
        setSliceSizes(orderBy(storeSliceSizes, ['size'], 'desc'));
    }, [dispatch]);

    const clearResults = React.useCallback(() => {
        setTotalStoreSize(0);
        setSliceSizes([]);
    }, []);

    return (
        <Container>
            <H2>Store Stats</H2>
            <ButtonContainer>
                <Button
                    onClick={measureStore}
                    size="sm"
                >
                    Measure Store
                </Button>
                <Button
                    onClick={clearResults}
                    size="sm"
                >
                    Clear Results
                </Button>
            </ButtonContainer>
            <BodyPrimary>
                total store: <BodyPrimary color="success">{totalStoreSize}kB</BodyPrimary>
            </BodyPrimary>
            <br />
            <BodyPrimary>
                slices: <BodyPrimary color="success">{sliceSizes.length}</BodyPrimary>
            </BodyPrimary>
            <br />
            <OrderedList type="1">
                {sliceSizes.map((slice) => (
                    <li key={slice.name}>
                        <BodyPrimary>
                            {slice.name}: <BodyPrimary color="success">{slice.size}kB</BodyPrimary>
                        </BodyPrimary>
                    </li>
                ))}
            </OrderedList>
        </Container>
    );
};

export default ReduxStoreSizeStats;

const Container = styled.div`
    margin: 20px 0;
`;

const OrderedList = styled.ol`
    max-height: 500px;
    overflow: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin: 20px 0;
    button {
        margin-right: 20px;
    }
`;
