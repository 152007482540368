import { BodyPrimary, H2 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { Close } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { fetchHealthCheckData, getHealthCheckData, getHealthCheckLoading } from '@/redux/modules/healthcheck';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { IconButton } from '@liveauctioneers/hammer-ui-core/iconButton';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import ApiPerformanceStats from './components/ApiPerformanceStats/ApiPerformanceStats';
import FeatureFlags from './components/FeatureFlags/FeatureFlags';
import React, { useEffect, useState } from 'react';
import ReduxStoreSizeStats from './components/ReduxStoreSizeStats/ReduxStoreSizeStats';
import styled, { createGlobalStyle } from 'styled-components';

const HealthCheckOverlay = () => {
    const [show, setShow] = useState(false);
    const dispatch = useAppDispatch();
    const healthCheckData = useAppSelector(getHealthCheckData);
    const healthCheckLoading = useAppSelector(getHealthCheckLoading);

    const handleListenForShortcut = (e: KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && (e.key === '`' || e.key === '/')) {
            setShow(true);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleListenForShortcut);
        return () => {
            window.removeEventListener('keydown', handleListenForShortcut);
        };
    }, []);

    useEffect(() => {
        if (show) {
            dispatch(fetchHealthCheckData());
        }
    }, [dispatch, show]);

    return (
        show && (
            <>
                <LockBackground />
                <StyledOverlayContainer>
                    <StyledOverlayBody>
                        <IconButton
                            className="absolute right-lg top-lg"
                            data-testid="health-check-overlay-close"
                            icon={Close}
                            onClick={() => setShow(false)}
                        />

                        <ReduxStoreSizeStats />
                        <FeatureFlags />
                        <StyledApiPerformanceStats />
                        <H2>
                            <FormattedMessage id="healthcheck.sectionTitle.deploymentData" />
                            <StyledButton
                                aria-label="fetch health check data"
                                loading={healthCheckLoading}
                                onClick={() => dispatch(fetchHealthCheckData())}
                                size="sm"
                            >
                                <FormattedMessage id="healthcheck.refresh" />
                            </StyledButton>
                        </H2>
                        <ul>
                            <li>
                                <BodyPrimary>
                                    Build Number: {healthCheckData.application.build_number || 'N/A'}
                                </BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>Deployment: {healthCheckData.application.deployment}</BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>
                                    Name: {healthCheckData.application.name || 'Local Development'}
                                </BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>Server Status: {healthCheckData.server.status}</BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>Server Uptime: {healthCheckData.server.uptime}</BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>
                                    Memory Available: {healthCheckData.server.memory.total_available}
                                </BodyPrimary>
                            </li>
                            <li>
                                <BodyPrimary>Memory Free: {healthCheckData.server.memory.total_free}</BodyPrimary>
                            </li>
                        </ul>
                    </StyledOverlayBody>
                </StyledOverlayContainer>
            </>
        )
    );
};

export default HealthCheckOverlay;

const StyledButton = styled(Button)`
    margin-left: 12px;
`;

const StyledApiPerformanceStats = styled(ApiPerformanceStats)`
    margin-bottom: 64px;
`;

const StyledOverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // this same z-index as modals
    z-index: 10400;
    background: ${({ theme }) => theme.colors.white};
    overflow: auto;
`;

const StyledOverlayBody = styled.div`
    padding: 64px;
    position: relative;
    text-align: left;

    a {
        padding-left: 0;
    }

    ul {
        background-color: ${({ theme }) => theme.colors.white} !important;
    }
`;

/* This prevents the background from scrolling */
const LockBackground = createGlobalStyle`
    html {
        overflow: hidden;
    }

    body {
        overflow: scroll;
        position: static;
    }
`;
