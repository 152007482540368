import React from 'react';

type Props = {};

const NoCookies = () => {
    return (
        <span
            style={{
                backgroundColor: '#ffebe8',
                border: '1px solid #dd3c10',
                display: 'block',
                margin: '100px auto',
                padding: '10px 20px 20px',
                textAlign: 'center',
                width: '340px',
            }}
        >
            <h2 style={{ marginBottom: '20px' }}>Cookies Required</h2>
            <p style={{ display: 'inline-block', maxWidth: '300px' }}>
                Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue.
            </p>
        </span>
    );
};

export default React.memo<Props>(NoCookies);
