import * as React from 'react';
import { getLocale } from '@/redux/modules/intl';
import { Locales } from '@/redux/modules/intl.types';
import { useAppSelector } from '@/redux/hooks';
import loadable from '@loadable/component';

// lazy load languages only used in the bidding console
import En from './providers/en';
import EnGb from './providers/en-gb';
const De = loadable(() => import('./providers/de'));
const Es = loadable(() => import('./providers/es'));
const Fr = loadable(() => import('./providers/fr'));
const It = loadable(() => import('./providers/it'));
const Ja = loadable(() => import('./providers/ja'));
const Ko = loadable(() => import('./providers/ko'));
const Nl = loadable(() => import('./providers/nl'));
const No = loadable(() => import('./providers/no'));
const Pl = loadable(() => import('./providers/pl'));
const Ru = loadable(() => import('./providers/ru'));
const Zh = loadable(() => import('./providers/zh'));

type Props = {
    children: React.ReactNode;
};

const IntlProvider = ({ children }: Props) => {
    const locale = useAppSelector(getLocale);

    switch (locale) {
        case Locales.en:
            return <En>{children}</En>;
        case Locales.enGb:
            return <EnGb>{children}</EnGb>;
        case Locales.de:
            return <De>{children}</De>;
        case Locales.es:
            return <Es>{children}</Es>;
        case Locales.fr:
            return <Fr>{children}</Fr>;
        case Locales.it:
            return <It>{children}</It>;
        case Locales.ja:
            return <Ja>{children}</Ja>;
        case Locales.ko:
            return <Ko>{children}</Ko>;
        case Locales.nl:
            return <Nl>{children}</Nl>;
        case Locales.no:
            return <No>{children}</No>;
        case Locales.pl:
            return <Pl>{children}</Pl>;
        case Locales.ru:
            return <Ru>{children}</Ru>;
        case Locales.zh:
            return <Zh>{children}</Zh>;
        default:
            return <En>{children}</En>;
    }
};

export default IntlProvider;
