import { KameleoonExperiments, KameleoonVariants } from '@/hooks/kameleoon/kameleoonExperiments';
import { useExperimentBase } from '@/hooks/kameleoon/utils/useExperimentBase';
import { useTrackExposedToExperiment } from '@/utils/analytics/experiments/trackExposedToExperiment.analytics';

const useIsInSimplifiedNavHighContrastExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.SimplifiedNavMultivariateTest,
        KameleoonVariants.SimplifiedNavMultivariateTest.VariantAHighContrast
    );

const useIsInSimplifiedNavWithBorderExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.SimplifiedNavMultivariateTest,
        KameleoonVariants.SimplifiedNavMultivariateTest.VariantBSearchWithBorder
    );

const useIsInSimplifiedNavControl = () =>
    useExperimentBase(
        KameleoonExperiments.SimplifiedNavMultivariateTest,
        KameleoonVariants.SimplifiedNavMultivariateTest.Control
    );

const useIsNotInSimplifiedNavExperiment = () =>
    useExperimentBase(
        KameleoonExperiments.SimplifiedNavMultivariateTest,
        KameleoonVariants.SimplifiedNavMultivariateTest.Off
    );

export const useSimplifiedNavExperiment = () => {
    const hasSimplifiedNavHighContrastVariant = useIsInSimplifiedNavHighContrastExperiment();
    const hasSimplifiedNavWithBorderVariant = useIsInSimplifiedNavWithBorderExperiment();
    const isInControl = useIsInSimplifiedNavControl();
    useIsNotInSimplifiedNavExperiment();

    let variant = 'Off';
    if (hasSimplifiedNavHighContrastVariant) {
        variant = 'High Contrast';
    } else if (hasSimplifiedNavWithBorderVariant) {
        variant = 'Border';
    } else if (isInControl) {
        variant = 'Control';
    }
    const trackExposure = useTrackExposedToExperiment('Simplified Nav', variant);
    trackExposure();

    return { hasSimplifiedNavHighContrastVariant, hasSimplifiedNavWithBorderVariant };
};
