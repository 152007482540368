import * as React from 'react';
import { getClientHydrated } from '@/redux/modules/client';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { openCompleteAccountSignUpModal } from '@/redux/modules/modal';
import { parseQueryParams, removeQueryParams, stringifyQuery } from '@/utils/queryParams';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

type QueryParams = {
    action?: string;
};

const SignupAction = () => {
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const isClientHydrated = useAppSelector(getClientHydrated);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = parseQueryParams<QueryParams>(location.search);
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        if (queryParams.action === 'signup' && isClientHydrated) {
            const query = removeQueryParams(queryParams, ['action']);
            const stringified = stringifyQuery(query);
            const newLocation = location.pathname + stringified ? '?' + stringified : '';
            !isLoggedIn && dispatch(openCompleteAccountSignUpModal());
            navigate(newLocation);
        }
    }, [dispatch, isClientHydrated, isLoggedIn, location, queryParams, navigate]);

    return null;
};

export default SignupAction;
