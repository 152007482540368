import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

/*eslint-disable sort-keys/sort-keys-fix */
const savedItemSortOrders = [
    SearchSortAndDirection.TimeMostRecent,
    SearchSortAndDirection.TimeNewest,
    SearchSortAndDirection.SavedMostRecent,
    SearchSortAndDirection.SavedOldest,
    SearchSortAndDirection.ViewedMostRecent,
    SearchSortAndDirection.ViewedOldest,
    SearchSortAndDirection.BestMatch,
    SearchSortAndDirection.EstimateHighest,
    SearchSortAndDirection.LotNumberLowest,
    SearchSortAndDirection.MostBids,
    SearchSortAndDirection.NewlyListed,
    SearchSortAndDirection.PriceHighest,
    SearchSortAndDirection.Promoted,
    SearchSortAndDirection.HammerPriceHighest,
] as const;

export default savedItemSortOrders;
