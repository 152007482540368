import { appendEngineTrackingCodeScript } from './utils/appendEngineTrackingCodeScript';
import { getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getUserChangedVariant, getUserSelectedVariant } from '@/redux/modules/kameleoon/kameleoon.selectors';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import { isE2ETest } from '@/redux/modules/browser';
import { KameleoonContext } from '@/components/KameleoonProvider/Context';
import { KameleoonExperimentIds, KameleoonExperimentsVariantIds } from '@/hooks/kameleoon/kameleoonExperiments';
import { useAppSelector } from '@/redux/hooks';
import { useCallback, useContext } from 'react';

/**
 * A react hook that returns the kameleoon client and the visitor code
 *
 * @see https://developers.kameleoon.com/feature-management-and-experimentation/web-sdks/js-sdk#methods
 */
export const useKameleoon = () => {
    return useContext(KameleoonContext);
};

/**
 * Type definition for the return value for kameleoon feature flag hooks
 * @see {useKameleoonFeatureFlag}
 */
type UseKameleoonFeatureFlagReturn = {
    /** Is the user in the provided variant? */
    isShowingFeature: boolean;
    /** Callback function for additional tracking of the feature flag */
    trackFeatureFlag: () => void;
};

/**
 * useKameleoonFeatureFlag - returns true if user is viewing given feature flag and provides a function to send tracking data to kameleoon
 * @param featureFlagKey - Kameleoon experiment ID
 * @param featureFlagVariant - Which variant to check
 * @returns {UseKameleoonFeatureFlagReturn}
 */
export const useKameleoonFeatureFlag = (
    featureFlagKey: KameleoonExperimentIds,
    featureFlagVariant: KameleoonExperimentsVariantIds[typeof featureFlagKey]
): UseKameleoonFeatureFlagReturn => {
    const { featureFlagVariants, kameleoonClient, visitorCode } = useKameleoon();
    const overrideWithUserSelectedVariant = useAppSelector(getUserChangedVariant);
    const userSelectedVariant = useAppSelector((state) => getUserSelectedVariant(state, featureFlagKey));
    const isE2E = useAppSelector(isE2ETest);
    const bidderId = useAppSelector(getBidderId);
    const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });

    const isShowingFeature = overrideWithUserSelectedVariant
        ? userSelectedVariant === featureFlagVariant
        : featureFlagVariants[featureFlagKey] === featureFlagVariant;

    const trackFeatureFlag = useCallback(() => {
        if (!kameleoonClient) {
            console.error('useKameleoonFeatureFlag - kameleoonClient is not initialized');
            return;
        }
        // don't track experiment data if the user has changed their viewed experiment, as the data isn't real anymore
        // don't track experiment data for e2e tests
        if (overrideWithUserSelectedVariant || isE2E) {
            return;
        }
        try {
            kameleoonClient?.getFeatureFlagVariationKey(visitorCodeOrBidderId, featureFlagKey);

            // Append kameleoon engine tracking code script for microsoft clarity
            const trackingCode = kameleoonClient?.getEngineTrackingCode(visitorCodeOrBidderId);
            appendEngineTrackingCodeScript(trackingCode);
        } catch (e) {
            console.error('Error tracking feature flag variation key', e);
        }
    }, [featureFlagKey, isE2E, kameleoonClient, overrideWithUserSelectedVariant, visitorCodeOrBidderId]);

    return { isShowingFeature: isE2E ? false : isShowingFeature, trackFeatureFlag };
};
