import { KameleoonContextType } from './Context';
import type { KameleoonClient as KameleoonClientJS } from '@kameleoon/javascript-sdk';
import type { KameleoonClient as KameleoonClientNode } from '@kameleoon/nodejs-sdk';

export const grabKameleoonData = (
    kameleoonClient: KameleoonClientJS | KameleoonClientNode,
    visitorCode: string
): KameleoonContextType => {
    if (!kameleoonClient) {
        console.error('grabKameleoonData - kameleoonClient is not initialized');
        return {
            featureFlags: [],
            featureFlagVariants: {},
            visitorCode,
        };
    }

    try {
        const featureFlags = [];
        const featureFlagVariants = {};

        kameleoonClient.getActiveFeatureFlags(visitorCode).forEach((value, key) => {
            featureFlags.push({ id: value.id, key });
            featureFlagVariants[key] = value.key;
        });

        return {
            featureFlags,
            featureFlagVariants,
            visitorCode,
        };
    } catch (e) {
        console.error('Error getting kameleoon feature flags', e);
        return {
            featureFlags: [],
            featureFlagVariants: {},
            visitorCode,
        };
    }
};
