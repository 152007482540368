const itemSortOrders = {
    /*eslint-disable */
    PRICE_HIGHEST_FIRST: 'PRICE_HIGHEST_FIRST',
    PRICE_LOWEST_FIRST: 'PRICE_LOWEST_FIRST',
    ESTIMATE_HIGHEST_FIRST: 'ESTIMATE_HIGHEST_FIRST',
    ESTIMATE_LOWEST_FIRST: 'ESTIMATE_LOWEST_FIRST',
    FEATURED_LOTS: 'FEATURED_LOTS',
    MOST_BIDS: 'MOST_BIDS',
    LEAST_BIDS: 'LEAST_BIDS',
    LOT_NUMBER_HIGHEST_FIRST: 'LOT_NUMBER_HIGHEST_FIRST',
    LOT_NUMBER_LOWEST_FIRST: 'LOT_NUMBER_LOWEST_FIRST',
    DATE_ASCENDING: 'DATE_ASCENDING',
    DATE_DESCENDING: 'DATE_DESCENDING',
    TIME_ENDING_FURTHEST: 'TIME_ENDING_FURTHEST',
    TIME_SOONEST_FIRST: 'TIME_SOONEST_FIRST',
    VIEWED_RECENT_FIRST: 'VIEWED_RECENT_FIRST',
    VIEWED_OLDEST_FIRST: 'VIEWED_OLDEST_FIRST',
    /*eslint-enable */
} as const;

export default itemSortOrders;
