import { AccountHolderWalkthroughSteps, NewAccountWalkthroughSteps } from '@/types/walkthroughs';
import {
    CompleteAccountModalContext,
    openCompleteAccountSignUpModal,
    openFeedbackModal,
    openLoginModal,
} from '@/redux/modules/modal';
import { en } from '@liveauctioneers/hammer-ui-liveauctioneers/intl/en';
import { EventContext } from '@/redux/modules/analytics';
import { Footer } from '@liveauctioneers/hammer-ui-liveauctioneers/footer';
import { FooterExperiment } from '@liveauctioneers/hammer-ui-liveauctioneers/footerExperiment';
import { getBaseUrl, getPartnersBaseUrl } from '@/redux/modules/config';
import { getIsWhiteLabel, getWhiteLabelName } from '@/redux/modules/whiteLabel';
import { getItemIdFromPath } from '@/utils/urls';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { onboardingWalkthroughCurrentStep } from '@/redux/modules/walkthroughs/walkthroughs.selectors';
import {
    setOnboardingWalkthroughOpenProperty,
    setOnboardingWalkthroughStep,
} from '@/redux/modules/walkthroughs/walkthroughs.reducer';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSimplifiedNavExperiment } from '@/hooks/kameleoon/useSimplifiedNavExperiment/useSimplifiedNavExperiment';

export const HammerFooter = () => {
    const dispatch = useAppDispatch();
    const baseUrl = useAppSelector(getBaseUrl);
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const whiteLabelName = useAppSelector(getWhiteLabelName);
    const partnersBaseUrl = useAppSelector(getPartnersBaseUrl);
    const { pathname } = useLocation();
    const itemId = getItemIdFromPath(pathname);
    const currentStep = useAppSelector(onboardingWalkthroughCurrentStep);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const { hasSimplifiedNavHighContrastVariant, hasSimplifiedNavWithBorderVariant } = useSimplifiedNavExperiment();

    const showFeedbackModal = useCallback(() => dispatch(openFeedbackModal()), [dispatch]);

    const login = useCallback(
        () =>
            dispatch(
                openLoginModal({
                    eventContext: EventContext.join,
                    id: itemId,
                    itemId,
                    type: CompleteAccountModalContext.Login,
                })
            ),
        [dispatch, itemId]
    );

    const signUp = useCallback(() => {
        dispatch(
            openCompleteAccountSignUpModal({
                eventContext: EventContext.join,
                id: itemId,
                itemId,
                type: CompleteAccountModalContext.Join,
            })
        );
        if (currentStep === AccountHolderWalkthroughSteps.Returning) {
            dispatch(setOnboardingWalkthroughOpenProperty(false));
            window.setTimeout(() => {
                dispatch(setOnboardingWalkthroughStep(NewAccountWalkthroughSteps.FirstTimeAnyPage));
            }, 400);
        }
    }, [currentStep, dispatch, itemId]);

    const props = {
        baseUrl,
        intl: en,
        isWhiteLabel,
        openFeedbackModal: showFeedbackModal,
        partnersBaseUrl,
        whiteLabelName,
    };

    return hasSimplifiedNavHighContrastVariant || hasSimplifiedNavWithBorderVariant ? (
        <FooterExperiment
            isUserLoggedIn={userLoggedIn}
            onLoginClicked={login}
            onSignUpClicked={signUp}
            {...props}
        />
    ) : (
        <Footer {...props} />
    );
};
