import * as React from 'react';
import { Basename } from '@/types/Basename';
import { DataLoaderParams } from '@/types/DataLoaderParams';
import { getBasename } from './getBasename';
import { GlobalState } from '@/redux/store';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { LoadableRouteConfig } from '@/routes';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { Store } from 'redux';
import { useAppSelector } from '@/redux/hooks';
import { useKameleoon } from '@/hooks/kameleoon/useKameleoon';
import ScrollMemory from './ScrollMemory';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

/**
 * Fetches any route preload data while changing to that route on the client-side
 * @see /src/server/ssr.js for server-side implementation
 */

const addTrailingSlashIfNeeded = (path: string) => (path === '' || path === Basename.ENGB ? path + '/' : path);

type RouteDataLoaderParams = {
    children: React.ReactNode;
    routes: LoadableRouteConfig[];
    store: Store<GlobalState>;
};

const RouteDataLoader = ({ children, routes, store }: RouteDataLoaderParams) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currRouterLocation = useLocation();
    const currentLocationKey = currRouterLocation.key;
    const previousLocationKey = usePrevious(currentLocationKey);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const wasLoggedIn = usePrevious(userLoggedIn);
    const { featureFlags, featureFlagVariants } = useKameleoon();

    const loadData = React.useCallback(() => {
        // Fetch preload data for current route
        const basename = getBasename();

        // Get all routes that match current location, basename isn't added since currRouterLocation comes from useLocation()
        const branches = matchRoutes(routes, currRouterLocation);

        let dataLoaderContext: { url?: string } = {};

        // Run each matched route's loadData function if it exists
        branches.forEach((branch) => {
            const { params, route } = branch;

            if (route.loadData) {
                const routeParams: DataLoaderParams = {
                    featureFlags,
                    featureFlagVariants,
                    location: {
                        ...currRouterLocation,
                        pathname: addTrailingSlashIfNeeded(currRouterLocation.pathname),
                    },
                    match: { params: params },
                    redirect: (route: string) => {
                        dataLoaderContext.url = addTrailingSlashIfNeeded(`${basename}${route}`.replace('//', '/'));
                        return navigate(dataLoaderContext.url);
                    },
                    routerState: location?.state ?? {},
                    store,
                };
                route.loadData(routeParams);
            }
        });

        // handle any redirects inside the page loadData function
        // unsure how this triggers in this flow, the set in redirect func above
        // only happens when it is called.
        if (dataLoaderContext.url) {
            return navigate(dataLoaderContext.url);
        }
    }, [routes, currRouterLocation, featureFlags, featureFlagVariants, location?.state, store, navigate]);

    React.useEffect(() => {
        // Load the page data if the location changes and the page is client side
        if (Boolean(previousLocationKey) && previousLocationKey !== currentLocationKey) {
            loadData();
        }
    }, [currentLocationKey, previousLocationKey, loadData]);

    React.useEffect(() => {
        // Reload the page data if the user logs in
        if (typeof wasLoggedIn !== 'undefined' && userLoggedIn && Boolean(wasLoggedIn) !== userLoggedIn) {
            loadData();
        }
    }, [userLoggedIn, wasLoggedIn, loadData]);

    return (
        <>
            <ScrollMemory />
            {children}
        </>
    );
};

export default RouteDataLoader;
