import * as React from 'react';
import { getIsBot } from '@/redux/modules/analytics';
import { getSailthruCustomerId } from '@/redux/modules/config';
import { useAppSelector } from '@/redux/hooks';

const SailthruInit = () => {
    const customerId = useAppSelector(getSailthruCustomerId);
    const isBot = useAppSelector(getIsBot);
    React.useEffect(() => {
        // @ts-ignore
        if (!isBot && window.Sailthru && window.Sailthru.init && !window.Sailthru.initRan) {
            // @ts-ignore
            window.Sailthru.init({
                autoTrackPageview: false,
                customerId,
                isCustom: true,
                useStoredTags: true,
            });
            // @ts-ignore
            window.Sailthru.initRan = true;
        }
    }, []); // eslint-disable-line
    return null;
};

export default SailthruInit;
