import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

export const catalogItemsSortOrders = [
    SearchSortAndDirection.LotNumberLowest,
    SearchSortAndDirection.MostBids,
    SearchSortAndDirection.PriceHighest,
    SearchSortAndDirection.PriceLowest,
    SearchSortAndDirection.EstimateHighest,
    SearchSortAndDirection.HammerPriceHighest,
    SearchSortAndDirection.Promoted,
] as const;
