export const FACEBOOK_PIXEL_ID = '992512390846699';

export enum Events {
    FollowedAuctioneer = 'FollowedAuctioneer',
    FollowedSearch = 'FollowedSearch',
    PageView = 'PageView',
    PlacedAbsenteeBid = 'PlacedAbsenteeBid',
    RegisterForAuction = 'RequestAuctionRegistration',
    RegisteredAccount = 'Lead',
    SavedItem = 'AddtoWishlist',
}

/**
 * Track Facebook Pixel standard events
 * @see https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
 * @examples
 * - track(Events.PageView);
 * - track(Events.FollowedAuctioneer, { sellerId: 123 });
 * @param title title of the event
 * @param data data of the event
 * @returns
 */
export const track = (event: Events, data: object = undefined) => {
    /* eslint-disable no-undef */
    if (data === undefined) {
        // @ts-expect-error
        return typeof fbq === 'function' && fbq('track', event);
    }
    // @ts-expect-error
    return typeof fbq === 'function' && fbq('track', event, JSON.stringify(data));
    /* eslint-enable no-undef */
};

/**
 * Returns noscript element with Facebook Pixel script id
 */
export const noscript = () => {
    const src = `https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`;
    const img = (
        <img
            height="1"
            src={src}
            style={{ display: 'none' }}
            width="1"
        />
    );
    return <noscript>{img}</noscript>;
};

/**
 * Returns script element with Facebook Pixel id
 */
export const script = () => {
    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `if (typeof fbq === 'undefined') { !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', ${FACEBOOK_PIXEL_ID}); }`,
            }}
            key="facebookPixel"
            type="text/javascript"
        />
    );
};
