import * as React from 'react';
import { DefaultQueryParams, GenericQueryParams } from './types/QueryParams';
import { IParseOptions } from 'qs';
import { Options, useModifyQueryParams } from './useModifyQueryParams';
import { useGetQueryParams } from './useGetQueryParams';

export { ModifyQueryParamsAction as Action } from './useModifyQueryParams';

export const useQueryParams = <QueryParams extends GenericQueryParams = DefaultQueryParams>(): {
    getQueryParams: (options?: IParseOptions) => QueryParams;
    modifyQueryParams: (options: Options<QueryParams>) => void;
} => {
    const getQueryParams = useGetQueryParams<QueryParams>();
    const modifyQueryParams = useModifyQueryParams();

    return React.useMemo(
        () => ({
            getQueryParams,
            modifyQueryParams,
        }),
        [getQueryParams, modifyQueryParams]
    );
};
