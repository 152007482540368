import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getBidderConsentSubmitted } from '@/redux/modules/consent/consent.selectors';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { isTermsOfServiceUnknown, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { submitConsent } from '@/redux/modules/consent/consent.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';

const tosLink = (
    <Link
        rel="noopener"
        target="_blank"
        to="/termsandconditions"
    >
        <FormattedMessage id="terms_and_conditions" />
    </Link>
);
const privacyLink = (
    <Link
        rel="noopener"
        target="_blank"
        to="/privacy"
    >
        <FormattedMessage id="privacy_policy" />
    </Link>
);
const cookieLink = (
    <Link
        rel="noopener"
        target="_blank"
        to="/cookie-policy"
    >
        <FormattedMessage id="cookie_policy" />
    </Link>
);

const TermsOfServiceBanner = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const checkout = pathname.includes('/console/');
    const biddingConsole = pathname.includes('/console/');
    const bidderConsentSubmitted = useAppSelector(getBidderConsentSubmitted);
    const termsOfServiceUnknown = useAppSelector(isTermsOfServiceUnknown);
    const submit = React.useCallback(() => {
        dispatch(
            submitConsent({
                consent: true,
                context: 'TermsOfServiceBanner',
            })
        );
    }, [dispatch]);

    const showTOSBanner = userLoggedIn && termsOfServiceUnknown && !isWhiteLabel && !checkout && !biddingConsole;

    return showTOSBanner ? (
        <StyledBanner>
            <StyledBannerInner>
                <StyledText>
                    <FormattedMessage
                        id="terms_banner"
                        values={{ cookieLink, privacyLink, tosLink }}
                    />
                </StyledText>
                <div>
                    <StyledSubmitButton
                        loading={bidderConsentSubmitted}
                        onClick={submit}
                        style={!isWhiteLabel ? 'critical' : 'primary'}
                    >
                        <FormattedMessage id="consent.agree" />
                    </StyledSubmitButton>
                </div>
            </StyledBannerInner>
        </StyledBanner>
    ) : null;
};

export default TermsOfServiceBanner;

const StyledBanner = styled.div`
    background-color: ${({ theme }) => theme.colors.grey400};
    font-size: 10px;
    display: flex;
    padding: 7px 20px;
    padding: 10px 20px;

    @media (min-width: 374px) {
        font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        font-size: 14px;
    }
`;

const StyledBannerInner = styled.div`
    flex: 1 1 auto;
    justify-content: space-between;
    display: flex;

    @media (min-width: ${({ theme }) => theme.breakpoints.pageWidthNum + 1}px) {
        margin: 0 auto;
        max-width: 860px;
        padding: 0 0 0 20px;
    }

    & > * {
        @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
            padding-right: 2em;
        }
    }

    & > a {
        &:hover {
            color: ${({ theme }) => theme.colors.black};
            text-decoration: underline;
        }
    }

    & > span {
        color: ${({ theme }) => theme.colors.grey300};
    }
`;

const StyledText = styled.div`
    margin-top: 0;
    margin-right: 1em;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-top: 12px;
    }
`;

const StyledSubmitButton = styled(Button)`
    margin-top: 0;
`;
