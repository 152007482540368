import { DEFAULT_SORT_ORDER } from '@/redux/modules/search/search.types';
import { DEFAULT_STATUS_FOR_ARCHIVED_SEARCH, DEFAULT_STATUS_FOR_ONLINE_SEARCH } from '@/utils/queryParams';
import { fetchSearchSuggestions, getSearchSuggestions } from '@/redux/modules/searchSuggestions';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getIsWhiteLabel, getWhiteLabelSellerId } from '@/redux/modules/whiteLabel';
import { getSearchInputBuffer } from '@/redux/modules/search/search.selectors';
import { getSearchPageSize } from '@/redux/modules/pagination';
import { getSearchUrlWithParams, getSellerUrl } from '@/utils/urls';
import { HeaderProps, SearchSuggestion } from '@liveauctioneers/hammer-ui-liveauctioneers/types';
import { safelyEscapeText } from '@/utils/safelyRender';
import { SearchCompletedTrigger } from '@/utils/analytics/core.types';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import { TrackSearchCompletedSearchType, useTrackSearchCompletedAnalytics } from '@/utils/analytics';
import { updateSearchInputBuffer, updateSearchSubmittedFromPage } from '@/redux/modules/search/search.actions';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation, useNavigate } from 'react-router';
import { useQueryParams } from '@/hooks/queryParams';
import {
    useTrackHeaderSearchHouseClicked,
    useTrackHeaderSearchSubmitted,
    useTrackHeaderSearchSuggestionClicked,
} from '@/hooks/kameleoon/tracking/useTrackHeaderSearch';

export const useSearchProps = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const sellerId = useAppSelector(getWhiteLabelSellerId);

    const relatedSuggestions = useAppSelector(getSearchSuggestions);
    const suggestions: SearchSuggestion[] = relatedSuggestions
        .map(({ suggestions }) => suggestions)
        .flat()
        .map((s) => {
            return {
                ...s,
                houseId: s?.houseId,
                label: safelyEscapeText(s?.label), // safelyEscape for if the house name contains double byte characters
            };
        });

    const getSuggestions = useDebouncedCallback((query: string) => dispatch(fetchSearchSuggestions(query)), 250);

    const { pathname } = useLocation();
    const isOnPriceGuidePage = pathname.includes('/price-guide/');
    const { getQueryParams } = useQueryParams();
    const { buyNow, status } = getQueryParams();
    const archiveSearchStatus = status === 'archive' && !buyNow && !isOnPriceGuidePage;
    const pageSize = useAppSelector(getSearchPageSize);
    const keyword = useAppSelector(getSearchInputBuffer);

    const trackSearchCompletedAnalytics = useTrackSearchCompletedAnalytics();
    const analyticsPageName = getAmplitudePageNameFromPath(pathname);

    // Kameleoon tracking
    const trackHeaderSearchSubmitted = useTrackHeaderSearchSubmitted();
    const trackHeaderSearchSuggestionClicked = useTrackHeaderSearchSuggestionClicked();
    const trackHeaderSearchHouseClicked = useTrackHeaderSearchHouseClicked();

    const searchPhrase = useCallback(
        (keyword: string) => {
            const searchStatus = archiveSearchStatus
                ? DEFAULT_STATUS_FOR_ARCHIVED_SEARCH
                : DEFAULT_STATUS_FOR_ONLINE_SEARCH;

            const params: {
                house_id?: string;
                keyword: string;
                sort: SearchSortAndDirection;
                status: typeof DEFAULT_STATUS_FOR_ARCHIVED_SEARCH | typeof DEFAULT_STATUS_FOR_ONLINE_SEARCH;
            } = {
                keyword,
                sort: DEFAULT_SORT_ORDER,
                status: searchStatus,
            };

            if (isWhiteLabel) {
                params.house_id = `${sellerId}`;
            }

            dispatch(updateSearchInputBuffer(keyword));
            dispatch(updateSearchSubmittedFromPage(analyticsPageName));
            const search = getSearchUrlWithParams(params);

            navigate(search);
        },
        [analyticsPageName, archiveSearchStatus, dispatch, isWhiteLabel, navigate, sellerId]
    );

    const searchHouse = useCallback(
        (houseId: number, houseName: string) => {
            trackSearchCompletedAnalytics({
                numberOfSearchResults: null,
                pageSize,
                searchKeywords: houseName,
                searchSuggestionUsed: true,
                searchType: TrackSearchCompletedSearchType.Auctioneer,
                trigger: SearchCompletedTrigger.SearchBar,
            });

            navigate({
                pathname: getSellerUrl(houseId, houseName),
            });
        },
        [navigate, pageSize, trackSearchCompletedAnalytics]
    );

    const search: HeaderProps['search']['handleSearch'] = useCallback(
        ({ houseId, query, suggestionClicked }) => {
            query = query.trim();

            if (query.length === 0) {
                return;
            } else if (houseId > 0) {
                searchHouse(houseId, query);
                // Kameleoon tracking
                trackHeaderSearchHouseClicked();
            } else {
                // Kameleoon tracking
                if (suggestionClicked) {
                    trackHeaderSearchSuggestionClicked();
                } else {
                    trackHeaderSearchSubmitted();
                }

                searchPhrase(query);
            }
        },
        [
            searchHouse,
            searchPhrase,
            trackHeaderSearchHouseClicked,
            trackHeaderSearchSubmitted,
            trackHeaderSearchSuggestionClicked,
        ]
    );

    return {
        getSuggestions,
        handleSearch: search,
        queryText: keyword,
        suggestions,
    };
};
