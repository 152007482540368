import * as React from 'react';
import { getAttributionData, getIsBot, recordAttributionAnalytics } from '@/redux/modules/analytics';
import { GlobalState } from '@/redux/store';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

/**
 * Record Segment.io attribution data on first page load
 */
const SegmentTracker = () => {
    const location = useLocation();
    const attributionData = useAppSelector((state: GlobalState) => getAttributionData(state, location));
    const isBot = useAppSelector(getIsBot);
    const prevBidderId = usePrevious(attributionData.bidderId);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (prevBidderId !== attributionData.bidderId && !isBot) {
            dispatch(recordAttributionAnalytics(attributionData));
        }
    }, [attributionData, isBot, prevBidderId, dispatch]);

    return null;
};

export default SegmentTracker;
