import * as React from 'react';
import { Close } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getCookie, setCookie } from '@/redux/modules/cookies';
import { getMobileBrowserOS } from '@/redux/modules/browser';
import { IconButton } from '@liveauctioneers/hammer-ui-core/iconButton';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import { useSimplifiedNavExperiment } from '@/hooks/kameleoon/useSimplifiedNavExperiment/useSimplifiedNavExperiment';
import ButtonLink from '@liveauctioneers/caterwaul-components/lib/ButtonLink/ButtonLink';
import styled from 'styled-components';

const COOKIE_MAX_AGE = 86400 * 30; // 30 days
const COOKIE_NAME = 'dismissableMobileBanner';
const COOKIE_BODY = 'dismissed';

const DismissableGetMobileBanner = () => {
    const dispatch = useAppDispatch();
    const mobileBrowserOS = useAppSelector(getMobileBrowserOS);
    const userLoggedIn = useAppSelector(isUserLoggedIn);
    const { pathname } = useLocation();
    const isCheckoutPage = pathname.includes('/checkout/');
    const biddingConsole = pathname.includes('/console/');

    const dismissed = useAppSelector((state) => getCookie(state, COOKIE_NAME)) === COOKIE_BODY;
    const { hasSimplifiedNavHighContrastVariant } = useSimplifiedNavExperiment();

    const setCookieChecked = React.useCallback((): void => {
        dispatch(
            setCookie({
                maxAge: COOKIE_MAX_AGE,
                name: COOKIE_NAME,
                value: COOKIE_BODY,
            })
        );
    }, [dispatch]);

    // Disable banner if experiment is active
    if (
        biddingConsole ||
        isCheckoutPage ||
        !userLoggedIn ||
        mobileBrowserOS === '' ||
        dismissed ||
        hasSimplifiedNavHighContrastVariant
    ) {
        return null;
    }

    const navTo =
        mobileBrowserOS === 'ios'
            ? 'https://itunes.apple.com/us/app/live-auctions/id321243082'
            : 'https://play.google.com/store/apps/details?id=com.liveauctioneers.and';

    return (
        <StyledMobileBanner id="mobileBanner">
            <StyledImage
                alt="mobile"
                src="https://p1.liveauctioneers.com/dist/images/mobile_icon.png"
            />
            <StyledNotificationTextDiv>
                <FormattedMessage id="get_notifications_from_favorite_auctioneers" />
            </StyledNotificationTextDiv>
            <StyledAppButton
                external
                url={navTo}
            >
                <FormattedMessage id="get_app" />
            </StyledAppButton>
            <IconButton
                className="absolute right-xs top-xs z-10"
                icon={Close}
                iconStyle="bold"
                onClick={setCookieChecked}
                size="xs"
                style="inverse"
                variant="transparent"
            />
        </StyledMobileBanner>
    );
};

export default DismissableGetMobileBanner;

const StyledMobileBanner = styled.div`
    display: flex;
    height: 63px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blue200};
    align-items: center;
    justify-content: space-around;
    position: relative;
    padding-right: 36px;
    padding-left: 16px;
`;

const StyledImage = styled.img`
    display: flex;
    max-height: 42px;
    max-width: 42px;
    border-radius: 5px;
`;

const StyledNotificationTextDiv = styled.div`
    text-align: center;
    max-width: 180px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.grey500};
    font-size: 14px;
`;

const StyledAppButton = styled(ButtonLink)`
    display: flex;
    width: 76px;
    height: 26px;
    background-color: ${({ theme }) => theme.colors.blue200};
    border: 1px solid ${({ theme }) => theme.colors.white};
    font-size: 12px;
    justify-content: center;
    padding: 0;
    line-height: normal;
    align-items: center;
`;
