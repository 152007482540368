import { getInitialWidth } from '@/redux/modules/windowSize';
import { useAppSelector } from '@/redux/hooks';
import useIsWiderThan from '@liveauctioneers/caterwaul-components/lib/hooks/useIsWiderThan';

const useIsWiderThanCustom: typeof useIsWiderThan = (width, initialSSRWidth) => {
    const initialWidth = useAppSelector(getInitialWidth);

    return useIsWiderThan(width, initialSSRWidth || initialWidth);
};

export default useIsWiderThanCustom;
