enum GeneralTopicKeys {
    BrokenOrMissing = 'brokenOrMissing',
    CancellationRequest = 'cancellationRequest',
    RefundForDoubleCharge = 'refundForDoubleCharge',
    ShippingAndPickupOptions = 'shippingAndPickupOptions',
    TaxExemption = 'taxExemption',
    TrackShippingAndDeliveryTime = 'trackShippingAndDeliveryTime',
}

export enum WonItemsPageTopicKeys {
    BrokenOrMissing = GeneralTopicKeys.BrokenOrMissing,
    CancellationRequest = GeneralTopicKeys.CancellationRequest,
    ChangePaymentMethod = 'changePaymentMethod',
    HowToPay = 'howToPay',
    Inauthentic = 'inauthentic',
    PickupAndSelfArranged = 'pickupAndSelfArranged',
    RefundForDoubleCharge = GeneralTopicKeys.RefundForDoubleCharge,
    ShipYourOrder = 'shipYourOrder',
    ShippingAndPickupOptions = GeneralTopicKeys.ShippingAndPickupOptions,
    TaxExemption = 'taxExemption',
    TrackShippingAndDelivery = GeneralTopicKeys.TrackShippingAndDeliveryTime,
}

export enum ItemPageTopicKeys {
    PlaceABid = 'placeABid',
    BidLimit = 'bidLimit',
    RegisterForAnAuction = 'registerForAnAuction',
    WinningOrLosingALot = 'winningOrLosingALot',
    RetractABid = 'retractABid',
    AddPaymentMethod = 'addPaymentMethod',
    ItemPriceAndFees = 'itemPriceAndFees',
    TaxExemption = GeneralTopicKeys.TaxExemption,
    ShippingOption = 'shippingOption',
    PickupAndSelfArranged = 'pickupAndSelfArranged',
    ShippingFee = 'shippingFee',
    TrackShippingAndDelivery = GeneralTopicKeys.TrackShippingAndDeliveryTime,
    ItemCondition = 'itemCondition',
    BrokenOrMissing = GeneralTopicKeys.BrokenOrMissing,
    RefundForDoubleCharge = GeneralTopicKeys.RefundForDoubleCharge,
    CancellationRequest = GeneralTopicKeys.CancellationRequest,
}

export enum TitleKeys {
    HowCanWeHelp = 'howCanWeHelp',
    MessageSent = 'messageSentTitle',
    Policy = 'policy',
    SendMessage = 'sendMessage',
}

export enum ContentKeys {
    CatalogClosed = 'catalogClosed',
    CatalogOpen = 'catalogOpen',
    LivePayments = 'livePayments',
    LiveShipping = 'liveShipping',
    NonLivePayments = 'nonLivePayments',
    NonLiveShipping = 'nonLiveShipping',
    lotClosed = 'lotClosed',
    lotOpen = 'lotOpen',
}

export enum MessageKeys {
    Primary = 'primaryMessage',
    Secondary = 'secondaryMessage',
    Tertiary = 'tertiaryMessage',
}

export enum MessageListKeys {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    E = 'e',
}

export enum InfoMessageKeys {
    BidLimitQuestions = 'bidLimitQuestions',
    HouseShippingQuestions = 'houseShippingQuestions',
    LiveShippingQuestions = 'shippingQuestions',
    NeedHelp = 'needHelp',
    PickupAndSelfArrangedShippingQuestions = 'pickupAndSelfArrangedShippingQuestions',
    RegistrationQuestions = 'registrationQuestions',
    ShippingHelp = 'shippingHelp',
}
