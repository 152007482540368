import * as React from 'react';
import { DefaultQueryParams, GenericQueryParams } from '@/hooks/queryParams/types/QueryParams';
import { useLocation } from 'react-router-dom';
import qs, { IParseOptions } from 'qs';

/**
 * Options can be useful. IE `{ parseBooleans: true, parseNumbers: true }`
 * @see https://www.npmjs.com/package/query-string
 */
export const useGetQueryParams = <QueryParams extends GenericQueryParams = DefaultQueryParams>() => {
    const { search } = useLocation();

    return React.useCallback(
        (options?: IParseOptions): QueryParams => qs.parse(search.substring(1), options) as QueryParams,
        [search]
    );
};
