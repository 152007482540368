import {
    getShippingEstimateAttempted,
    getShippingEstimateIsTooExpensive,
} from '@/redux/modules/shipping/shipping.selectors';
import { ModalPageManager } from '@/types/modal/ModalPageManager.types';
import { useAppSelector } from '@/redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

export enum CustomShippingRateModalPages {
    CalculateQuoteForm = 'calculateQuoteForm',
    TooExpensiveForm = 'tooExpensiveForm',
    TooExpensiveRequestSuccess = 'tooExpensiveSuccess',
}

export function useCustomShippingRateModalPages(
    itemId: number,
    isOpen: boolean
): ModalPageManager<CustomShippingRateModalPages> {
    const wasOpen = usePrevious(isOpen);
    const triedToCalculateEstimate = useAppSelector((state) => getShippingEstimateAttempted(state, itemId));
    const isTooExpensive = useAppSelector((state) => getShippingEstimateIsTooExpensive(state, itemId));
    const [currentPage, setCurrentPage] = useState(
        triedToCalculateEstimate && isTooExpensive
            ? CustomShippingRateModalPages.TooExpensiveForm
            : CustomShippingRateModalPages.CalculateQuoteForm
    );

    useEffect(() => {
        if (isOpen && !wasOpen) {
            setCurrentPage(
                triedToCalculateEstimate && isTooExpensive
                    ? CustomShippingRateModalPages.TooExpensiveForm
                    : CustomShippingRateModalPages.CalculateQuoteForm
            );
        }
    }, [isOpen, isTooExpensive, triedToCalculateEstimate, wasOpen]);

    useEffect(() => {
        if (isOpen && wasOpen && isTooExpensive && currentPage === CustomShippingRateModalPages.CalculateQuoteForm) {
            setCurrentPage(CustomShippingRateModalPages.TooExpensiveForm);
        }
    }, [currentPage, isOpen, isTooExpensive, wasOpen]);

    const goToNextPage = useCallback(() => {
        setCurrentPage(CustomShippingRateModalPages.TooExpensiveRequestSuccess);
    }, []);

    return {
        currentPage,
        goToNextPage,
        setCurrentPage,
    };
}
