import { autoOpenSignUpModal } from '@/redux/modules/autoPopModal/autoPopModal.actions';
import { bindActionCreators } from 'redux';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getOpenModal } from '@/redux/modules/modal.selectors';
import {
    getUiDateLastShown,
    getUiModalShown,
    shouldStopAutoPopup,
} from '@/redux/modules/autoPopModal/autoPopModal.selectors';
import { isE2ETest, isUiMobile } from '@/redux/modules/browser';
import { isUserAtLeastPartiallyRegistered } from '@/redux/modules/account/user/user.selectors';
import { modalTypes } from '@/enums';
import { setBidderHasLoggedInCookie, setJoinModalLastSeenCookie } from '@/redux/modules/cookies';

const mapStateToProps = (state) => {
    const isE2E = isE2ETest(state);
    const isMobile = Boolean(isUiMobile(state));
    const openModal = getOpenModal(state);
    const userLoggedIn = isUserAtLeastPartiallyRegistered(state);
    const modalShown = getUiModalShown(state);
    const lastDateShown = getUiDateLastShown(state);
    const preventAutoPopup = shouldStopAutoPopup(state);

    return {
        isE2E,
        isMobile,
        lastDateShown,
        modalShown,
        openModal,
        preventAutoPopup,
        userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            autoOpenSignUpModal,
            setBidderHasLoggedInCookie,
            setJoinModalLastSeenCookie,
        },
        dispatch
    );

type Props = {
    autoOpenSignUpModal: typeof autoOpenSignUpModal;
    isE2E: boolean;
    isMobile: boolean;
    lastDateShown: string;
    modalShown: boolean;
    openModal: string;
    preventAutoPopup: boolean;
    route: string;
    setBidderHasLoggedInCookie: typeof setBidderHasLoggedInCookie;
    setJoinModalLastSeenCookie: typeof setJoinModalLastSeenCookie;
    userLoggedIn: boolean;
};

class AutoPopModalContainer extends Component<Props> {
    componentDidMount() {
        const { isMobile, modalShown, openModal, preventAutoPopup, userLoggedIn } = this.props;

        const shouldShowModal = !preventAutoPopup && !modalShown && openModal === modalTypes.NONE && !userLoggedIn;

        if (shouldShowModal) {
            if (isMobile) {
                // wait to attach the listener to avoid popping immediately on page load
                this.timeout = setTimeout(
                    () =>
                        window.addEventListener('touchmove', this.onTouchMove, {
                            passive: false,
                        }),
                    1500
                );
            } else {
                this.timeout = setTimeout(() => window.addEventListener('scroll', this.onScroll, false), 1500);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { lastDateShown, modalShown, userLoggedIn } = prevProps;

        if (!userLoggedIn && this.props.userLoggedIn) {
            this.clearEffects();
            this.props.setBidderHasLoggedInCookie();
        }

        if ((!modalShown && this.props.modalShown) || lastDateShown !== this.props.lastDateShown) {
            this.props.setJoinModalLastSeenCookie();
        }
    }

    componentWillUnmount() {
        this.clearEffects();
    }

    clearEffects() {
        clearTimeout(this.timeout);
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = (e) => {
        e.preventDefault();
        if (this.props.isE2E) {
            return;
        }

        setTimeout(() => {
            this.props.autoOpenSignUpModal(this.props.route);
        }, 2000);
        window.removeEventListener('scroll', this.onScroll);
    };

    onTouchMove = (e) => {
        // https://www.chromestatus.com/features/5093566007214080
        e.preventDefault();
        setTimeout(() => {
            this.props.autoOpenSignUpModal(this.props.route);
        }, 2000);
        window.removeEventListener('touchmove', this.onTouchMove);
    };

    timeout: any;

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoPopModalContainer);
