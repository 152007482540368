import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { Close, Warning } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { enableBrowserNotificationsUrl } from '@/utils/urls';
import {
    forceEnableNotificationsBannerDismiss,
    getForceEnableBanner,
    getPermission,
    requestBrowserPermission,
} from '@/redux/modules/notification';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getCookie, setCookie } from '@/redux/modules/cookies';
import { H4 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { IconButton } from '@liveauctioneers/hammer-ui-core/iconButton';
import { NotificationPermissions } from '@/enums/notificationPermissions.enum';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import { useSimplifiedNavExperiment } from '@/hooks/kameleoon/useSimplifiedNavExperiment/useSimplifiedNavExperiment';
import { useTrackBrowserNotificationsClicked } from '@/utils/analytics/notifications';
import ButtonLink from '@liveauctioneers/caterwaul-components/lib/ButtonLink/ButtonLink';
import styled, { css } from 'styled-components';
const COOKIE_MAX_AGE = 86400 * 365; // 365 days
const COOKIE_NAME = 'dismissableNotificationBanner';
const COOKIE_BODY = 'dismissed';

const DismissableGetWebNotificationsBanner = () => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const isCheckoutPage = pathname.includes('/checkout/');
    const biddingConsole = pathname.includes('/console/');
    const forceEnableBanner = useAppSelector(getForceEnableBanner);
    const permission = useAppSelector(getPermission);
    const dismissed = useAppSelector((state) => getCookie(state, COOKIE_NAME)) === COOKIE_BODY;
    const { hasSimplifiedNavHighContrastVariant } = useSimplifiedNavExperiment();
    const trackBrowserNotificationsClicked = useTrackBrowserNotificationsClicked();

    const setCookieChecked = React.useCallback((): void => {
        dispatch(
            setCookie({
                maxAge: COOKIE_MAX_AGE,
                name: COOKIE_NAME,
                value: COOKIE_BODY,
            })
        );
        dispatch(forceEnableNotificationsBannerDismiss());
    }, [dispatch]);

    const onClickEnableNotifications = () => {
        dispatch(requestBrowserPermission());
        trackBrowserNotificationsClicked();
    };

    // Disable banner if experiment is active
    if (biddingConsole || isCheckoutPage || permission === '' || hasSimplifiedNavHighContrastVariant) {
        return null;
    }

    if (!forceEnableBanner) {
        if (permission === NotificationPermissions.Granted || Boolean(dismissed)) {
            return null;
        }
    }

    const disallowedNotifications = permission === NotificationPermissions.Denied;

    return (
        <StyledBanner
            data-testid="webNotificationsBanner"
            id="webNotificationsBanner"
        >
            {disallowedNotifications ? (
                <StyledInnerContainer
                    data-testid="webNotificationsDenied"
                    id="webNotificationsDenied"
                >
                    <Warning
                        className="mr-xs text-warning-400"
                        size="sm"
                        style="fill"
                    />
                    <StyledWhiteText>
                        <FormattedMessage id="notifications.modify_your_browser_preferences" />
                    </StyledWhiteText>
                    <StyledButtonLink
                        external
                        rel="noopener"
                        target="_blank"
                        url={enableBrowserNotificationsUrl}
                    >
                        <FormattedMessage id="top_learn_more" />
                    </StyledButtonLink>
                </StyledInnerContainer>
            ) : (
                <StyledInnerContainer
                    data-testid="webNotificationsDefault"
                    id="webNotificationsDefault"
                >
                    <StyledWhiteText>
                        <FormattedMessage id="notifications.get_notifications_when_outbid" />
                    </StyledWhiteText>
                    <StyledButton
                        onClick={onClickEnableNotifications}
                        size="sm"
                    >
                        {formatMessage({
                            id: 'notifications.enable_browser_notifications',
                        })}
                    </StyledButton>
                </StyledInnerContainer>
            )}
            <IconButton
                className="absolute right-xs top-xs z-10"
                icon={Close}
                onClick={setCookieChecked}
                size="sm"
                style="inverse"
            />
        </StyledBanner>
    );
};

export default DismissableGetWebNotificationsBanner;

const StyledBanner = styled.div`
    width: 100%;
    display: flex;
    background-color: ${({ theme }) => theme.colors.blue200};
    height: 62px;
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 1000;

    @media (max-width: 975px) {
        padding-right: 40px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        display: none;
    }
`;

const StyledInnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const buttonStyles = css`
    color: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.colors.white} 1px solid;
    margin-left: 10px;

    &:hover {
        color: ${({ theme }) => theme.colors.blue200};
        border: ${({ theme }) => theme.colors.white} 1px solid;
        background: ${({ theme }) => theme.colors.white};
    }
`;

const StyledWhiteText = styled(H4)`
    && {
        color: ${({ theme }) => theme.colors.white};
    }
`;

const StyledButtonLink = styled(ButtonLink)`
    ${buttonStyles}
`;

const StyledButton = styled(Button)`
    ${buttonStyles}
`;
