import { getNotificationCount } from '@/redux/modules/notification';
import { useAppSelector } from '@/redux/hooks';
import React from 'react';

const useAddNotificationCountToTitle = (title: string = '') => {
    const count = useAppSelector(getNotificationCount);

    return React.useMemo(() => {
        if (count > 99) {
            return `(99+) ${title}`;
        } else if (count > 0) {
            return `(${count}) ${title}`;
        }
        return title;
    }, [title, count]);
};

export default useAddNotificationCountToTitle;
