export enum PaymentProviders {
    Unknown = 0,
    // Square is no longer supported, leaving as a reference point
    // SQUARE,
    //Stripe is officially deprecated and shouldn't need to be used much
    /** @deprecated */
    Stripe = 2,
    Payrix,
    Paysafe,
}
