import * as React from 'react';
import { getSignifydSessionId, saveSignifydSessionId } from '@/redux/modules/signifyd';
import { getUseSignifyd } from '@/redux/modules/config';
import { GlobalState } from '@/redux/store';
import { Helmet } from 'react-helmet-async';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { v4 as uuidv4 } from 'uuid';

const Signifyd = () => {
    const dispatch = useAppDispatch();

    const signifydSessionId = useAppSelector((state: GlobalState) => getSignifydSessionId(state));
    const useSignifyd = useAppSelector((state: GlobalState) => getUseSignifyd(state));
    const userIsLoggedIn = useAppSelector(isUserLoggedIn);

    React.useEffect(() => {
        if (useSignifyd && userIsLoggedIn) {
            const newSessionId = uuidv4();
            dispatch(saveSignifydSessionId(newSessionId));
        }
    }, [dispatch, useSignifyd, userIsLoggedIn]);

    if (!signifydSessionId) {
        return null;
    }

    return (
        <Helmet>
            <script
                data-order-session-id={signifydSessionId}
                defer
                id="sig-api"
                src="https://cdn-scripts.signifyd.com/api/script-tag.js"
                type="text/javascript"
            />
        </Helmet>
    );
};

export default Signifyd;
