import { getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import { KameleoonGoal } from './kameleoonGoals';
import { useAppSelector } from '@/redux/hooks';
import { useCallback } from 'react';
import { useKameleoon } from './useKameleoon';

type UseTrackConversionParams = {
    goal: KameleoonGoal;
};

/**
 * Type definition for the return value of useTrackConversion
 * @see {useTrackConversion}
 */
/** Callback function used to fire the tracking event - bidAmount should be passed in for goals where bids are being tracked*/
type UseTrackConversionReturn = (bidAmount?: number) => void;

/**
 * useTrackConvsersion -  returns a function to track conversion for Kameleoon experiments
 *
 * @see https://developers.kameleoon.com/feature-management-and-experimentation/web-sdks/js-sdk/#trackconversion
 *
 * @param goal - Kameleoon Goal to be tracked
 * @see {KameleoonGoal}
 * @returns {UseTrackConversionReturn}
 */
export const useTrackConversion = ({ goal }: UseTrackConversionParams): UseTrackConversionReturn => {
    const { kameleoonClient, visitorCode } = useKameleoon();
    const bidderId = useAppSelector(getBidderId);
    const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });

    const trackConversion = useCallback(
        (bidAmount?: number) => {
            if (!kameleoonClient) {
                console.warn('kameleoonClient is not initialized in `useTrackConversion` for goal', goal.goalName);
                return;
            }

            try {
                kameleoonClient.trackConversion({
                    goalId: goal.goalId,
                    revenue: bidAmount,
                    visitorCode: visitorCodeOrBidderId,
                });
            } catch (e) {
                console.error(`Error tracking conversion for goal ${goal.goalName}`, e);
            }
        },
        [goal.goalId, goal.goalName, kameleoonClient, visitorCodeOrBidderId]
    );

    return trackConversion;
};
