import * as React from 'react';
import { Locales } from '@/redux/modules/intl.types';
import { IntlProvider as RIProvider } from '@liveauctioneers/hammer-ui-core/intl';
import enGb from '@/intl/en-gb';

type Props = {
    children: React.ReactNode;
};

const IntlProvider = ({ children }: Props) => {
    return (
        <RIProvider
            key={Locales.enGb}
            locale={Locales.enGb}
            messages={enGb}
        >
            {children}
        </RIProvider>
    );
};

export default IntlProvider;
