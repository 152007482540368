const modalTypes = {
    ABSENTEE_BID: 'ABSENTEE_BID',
    ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
    ADD_SEARCH_ALERT: 'ADD_SEARCH_ALERT',
    ADDRESS: 'ADDRESS',
    ADDRESS_VERIFY_MODAL: 'ADDRESS_VERIFY_MODAL',
    BID_CONSOLE_EXPLAINER: 'BID_CONSOLE_EXPLAINER',
    BID_LIMIT_EXCEEDED: 'BID_LIMIT_EXCEEDED',
    BUY_NOW: 'BUY_NOW',
    BUY_NOW_INQUIRY: 'BUY_NOW_INQUIRY',
    CATALOG_ANNOUNCEMENT: 'CATALOG_ANNOUNCEMENT',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CLASSIC_BIDDING_CONSOLE: 'CLASSIC_BIDDING_CONSOLE',
    CLEAR_CACHE: 'CLEAR_CACHE',
    COMPLETE_ACCOUNT_SIGNUP: 'COMPLETE_ACCOUNT_SIGNUP',
    CONFIRM_CONSIGNMENT: 'CONFIRM_CONSIGNMENT',
    CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
    CONFIRM_UNFOLLOW_SEARCH: 'CONFIRM_UNFOLLOW_SEARCH',
    CONSENT: 'CONSENT',
    DELETE_ACCOUNT_CONFIRMATION: 'DELETE_ACCOUNT_CONFIRMATION',
    DESCRIPTION: 'DESCRIPTION',
    DOWNLOAD_MOBILE: 'DOWNLOAD_MOBILE',
    ENABLE_NOTIFICATIONS: 'ENABLE_NOTIFICATIONS',
    EXCLUDE_HOUSE: 'EXCLUDE_HOUSE',
    FEEDBACK: 'FEEDBACK',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    GET_HELP: 'GET_HELP',
    HEALTHCHECK: 'HEALTHCHECK',
    INSTALL_FLASH: 'INSTALL_FLASH',
    LIVE_BID: 'LIVE_BID',
    LIVE_SHIPPING_DEADLINE: 'OPEN_LIVE_SHIPPING_DEADLINE',
    LOGIN: 'LOGIN',
    MAKE_OFFER: 'MAKE_OFFER',
    NONE: 'NONE',
    ON_THE_HUNT: 'ON_THE_HUNT',
    PAY_INVOICE: 'PAY_INVOICE',
    PHONE_VERIFICATION: 'PHONE_VERIFICATION',
    PLACE_ABSENTEE_BID: 'PLACE_ABSENTEE_BID',
    PREFERENCES: 'PREFERENCES',
    PURCHASE_PROTECTION: 'PURCHASE_PROTECTION',
    REGISTER_FOR_AUCTION: 'REGISTER_FOR_AUCTION',
    REPORT_REVIEW: 'REPORT_REVIEW',
    RESEND_CONFIRMATION_EMAIL: 'RESEND_CONFIRMATION_EMAIL',
    RETRACT_BID: 'RETRACT_BID',
    REVIEWS: 'REVIEWS',
    SEND_SELLER_CONSIGNMENT_MESSAGE: 'SEND_SELLER_CONSIGNMENT_MESSAGE',
    SEND_SELLER_MESSAGE: 'SEND_SELLER_MESSAGE',
    SEND_SELLER_PHOTO_REQUEST: 'SEND_SELLER_PHOTO_REQUEST',
    SET_PASSWORD: 'SET_PASSWORD',
    SHIPPING_AND_PAYMENT: 'SHIPPING_AND_PAYMENT',
    SIGN_UP: 'SIGN_UP',
    SMS_CONFIRMATION: 'SMS_CONFIRMATION',
    TAX_EXEMPT: 'TAX_EXEMPT',
    TERMS: 'TERMS',
    WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
    ZIP_INFO_MODAL: 'ZIP_INFO_MODAL',
} as const;

export default modalTypes;
