import * as React from 'react';
import { BodyPrimary } from '@liveauctioneers/hammer-ui-core/text';
import { ExternalRouteButton } from '../RouteButton/RouteButton';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getUserData } from '@/redux/modules/account/user/user.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const SuspendedBidderBanner = () => {
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const biddingConsole = pathname.includes('/console/');
    const { creditCardValidated, hasEverRegisteredForAuction, standing } = useAppSelector(getUserData);
    const show = !biddingConsole && standing === 's' && (creditCardValidated || hasEverRegisteredForAuction);

    if (!show) {
        return null;
    }

    return (
        <StyledSuspendedBidderBanner>
            <StyledInnerBidderBanner>
                <StyledText>
                    <FormattedMessage id="suspendedBidderBanner.text" />
                </StyledText>
                <StyledSubmit
                    target="_blank"
                    to="https://help.liveauctioneers.com/article/146-how-do-i-restore-my-suspended-account"
                >
                    {formatMessage({ id: 'suspendedBidderBanner.button' })}
                </StyledSubmit>
            </StyledInnerBidderBanner>
        </StyledSuspendedBidderBanner>
    );
};

export default SuspendedBidderBanner;

const StyledSuspendedBidderBanner = styled.div`
    background-color: ${({ theme }) => theme.colors.grey400};
    display: flex;
    padding: 16px;
    position: sticky;
    top: 0;
    z-index: 1000;
`;

const StyledInnerBidderBanner = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
`;

const StyledText = styled(BodyPrimary)`
    margin-right: 24px;
`;

const StyledSubmit = styled(ExternalRouteButton)`
    margin-top: 0;
`;
