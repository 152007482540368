import * as React from 'react';
import { BodyPrimary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { PerformanceLog } from '@/redux/modules/apiPerformanceStats';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled, { css } from 'styled-components';
import Table from '@liveauctioneers/caterwaul-components/lib/Table/Table';

type ApiStatsTableProps = {
    className?: string;
    client: PerformanceLog | null;
    server: PerformanceLog | null;
    type: 'client' | 'server';
};

const ApiStatsTable = ({ className, client, server, type }: ApiStatsTableProps) => {
    if ((type === 'client' && !client) || (type === 'server' && !server)) {
        return <BodyPrimary>loading {type}...</BodyPrimary>;
    }

    const apiLog = type === 'client' ? client : server;

    return (
        <Table className={className}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.number" />
                    </th>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.method" />
                    </th>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.status" />
                    </th>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.apiUrl" />
                    </th>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.time" />
                    </th>
                    <th>
                        <FormattedMessage id="healthcheck.tableHeaders.apiStatsTable.waterfall" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {apiLog?.stats.requests.map(({ error, method, statusCode, totalTime, url, waterfall }, i) => {
                    const isDupedApi = apiLog.stats.requests.filter((req) => req.url === url).length > 1;
                    const isDupedApiOnClientFromServer =
                        type === 'client' && server?.stats.requests.filter((req) => req.url === url).length >= 1;
                    const notGreat = totalTime > 100;
                    const notGood = totalTime > 200;
                    const terrible = totalTime > 300;

                    return (
                        <Tr
                            $notGood={notGood}
                            $notGreat={notGreat}
                            $terrible={terrible}
                            key={i}
                        >
                            <Td>
                                <BodyPrimary>{i + 1}</BodyPrimary>
                            </Td>
                            <Td>
                                <BodyPrimary>{method}</BodyPrimary>
                            </Td>
                            <Td $hasError={Boolean(error) || statusCode >= 400}>
                                <BodyPrimary>{statusCode}</BodyPrimary>
                            </Td>
                            <Td $hasWarning={isDupedApi || isDupedApiOnClientFromServer}>
                                <Link
                                    rel="noopener"
                                    target="_blank"
                                    to={url}
                                >
                                    {url}
                                </Link>
                                {isDupedApi && (
                                    <BodyPrimary
                                        className="hui-text-h4"
                                        color="critical"
                                    >
                                        (duped {type} api)
                                    </BodyPrimary>
                                )}
                                {isDupedApiOnClientFromServer && (
                                    <BodyPrimary
                                        className="hui-text-h4"
                                        color="critical"
                                    >
                                        (duped client api from server)
                                    </BodyPrimary>
                                )}
                            </Td>
                            <Td>
                                <BodyPrimary>{Math.round(totalTime)}ms</BodyPrimary>
                            </Td>
                            <Td>
                                <BodyPrimary>{waterfall}</BodyPrimary>
                            </Td>
                        </Tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default ApiStatsTable;

const Tr = styled.tr<{
    $notGood: boolean;
    $notGreat: boolean;
    $terrible: boolean;
}>`
    ${({ $notGood, $notGreat, $terrible, theme }) => {
        if ($terrible) {
            return css`
                background-color: ${theme.colors.red300};
            `;
        } else if ($notGood) {
            return css`
                background-color: ${theme.colors.redLight};
            `;
        } else if ($notGreat) {
            return css`
                background-color: ${theme.colors.red400};
            `;
        } else {
            return css`
                background-color: ${theme.colors.greenLight};
            `;
        }
    }}
`;

const Td = styled.td<{ $hasError?: boolean; $hasWarning?: boolean }>`
    max-width: 250px;
    overflow-wrap: break-word;
`;
