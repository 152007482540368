import { createContext, ReactNode } from 'react';
import type { FeatureFlagType, KameleoonClient } from '@kameleoon/javascript-sdk';

export type KameleoonContextType = {
    featureFlagVariants: { [key: string]: string | undefined };
    featureFlags: FeatureFlagType[];
    kameleoonClient?: KameleoonClient;
    visitorCode?: string;
};

export type KameleoonContextProps = KameleoonContextType & {
    children: ReactNode;
};

export const KameleoonContext = createContext<KameleoonContextType>({
    featureFlags: [],
    featureFlagVariants: {},
    kameleoonClient: {} as KameleoonClient,
    visitorCode: undefined,
});

export const KameleoonProvider = ({ children, ...value }: KameleoonContextProps) => {
    return <KameleoonContext.Provider value={value}>{children}</KameleoonContext.Provider>;
};
