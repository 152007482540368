import * as React from 'react';
import { getIsWhiteLabel } from '@/redux/modules/whiteLabel';
import { openFeedbackModal } from '@/redux/modules/modal';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import FeedbackWidget from '@liveauctioneers/caterwaul-components/lib/FeedbackWidget/FeedbackWidget';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';
import useIsWiderThan from '@/hooks/useIsWiderThan';

const FeedbackWidgetContainer = () => {
    const dispatch = useAppDispatch();
    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const { pathname } = useLocation();
    const isBiddingConsole = pathname.includes('/console/');
    const isCheckout = pathname.includes('/checkout/');
    const isCapital = pathname.includes('/capital');
    const isAuthRedirect = pathname.includes('/auth/');
    const isVideo = pathname.includes('/video/');
    const isMobileConsole = useIsNarrowerThan(999) && isBiddingConsole;

    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();
    const isMobile = !useIsWiderThan(tabletNarrowWidthNum);

    const onWidgetClick = React.useCallback(() => {
        dispatch(openFeedbackModal());
    }, [dispatch]);

    if (isCapital || isVideo || isCheckout || isWhiteLabel || isMobileConsole || isAuthRedirect || isMobile) {
        return null;
    }

    return (
        <FeedbackWidget
            onClick={onWidgetClick}
            showWidget
        />
    );
};

export default FeedbackWidgetContainer;
