import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { Link as RRLink, useHref, useLinkClickHandler } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

type Props = Omit<React.ComponentProps<typeof Button> & React.ComponentProps<typeof RRLink>, 'href'>;

export const RouteButton = React.forwardRef<HTMLAnchorElement, Props>(
    ({ onClick, replace = false, state = null, target = '', to, ...rest }: Props, ref) => {
        const href = useHref(to);
        const handleClick = useLinkClickHandler(to, {
            replace,
            state,
            target,
        });
        return (
            <StyledButton
                forwardedAs="a"
                href={href}
                onClick={(event) => {
                    onClick?.(event);
                    if (!event.defaultPrevented) {
                        handleClick(event);
                    }
                }}
                ref={ref}
                target={target}
                {...rest}
            />
        );
    }
);

export const ExternalRouteButton = React.forwardRef<HTMLAnchorElement, Props>(
    ({ onClick, to, ...rest }: Props, ref) => {
        return (
            <StyledButton
                forwardedAs="a"
                href={to as string}
                onClick={(event) => {
                    onClick?.(event);
                }}
                ref={ref}
                rel="nofollow"
                {...rest}
            />
        );
    }
);

const StyledButton = styled(Button)`
    text-decoration: none;
`;
