import * as React from 'react';
import { getIsBot } from '@/redux/modules/analytics';
import { getIsOnTheHuntPage } from '@/redux/modules/autoPopModal/autoPopModal.actions';
import { getIsReseller } from '@/redux/modules/reseller';
import { getOpenModal, getOpenModalData } from '@/redux/modules/modal.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router-dom';
import AutoPopModalContainer from '@/components/Modals/AutoPop/AutoPopModalContainer';
import loadable from '@loadable/component';
import modalTypes from '@/enums/modalTypes';
import PurchaseProtectionModal from './PurchaseProtection/PurchaseProtectionModal';
const AddCreditCardModal = loadable(() => import('./AddCreditCard/AddCreditCardModal'));
const AddressModal = loadable(() => import('./Address/AddressModalContainer'));
const AddressVerifyModal = loadable(() => import('./AddressVerifyModal/AddressVerifyModal'));
const BidConsoleExplainer = loadable(() => import('./BidConsoleExplainer/BidConsoleExplainer'));
const BidLimitExceededModal = loadable(() => import('./BidLimitExceeded/BidLimitExceededModal'));
const BuyNowModal = loadable(() => import('./BuyNow/BuyNowModal'));
const CatalogAnnouncmentModal = loadable(() => import('./CatalogAnnouncement/CatalogAnnouncementModal'));
const ChangePasswordModal = loadable(() => import('./ChangePassword/ChangePasswordModal'));
const CompleteAccountSignupModal = loadable(() => import('./AuthModal/CompleteAccount/AuthCompleteAccountModal'));
const ConfirmConsignmentModal = loadable(() => import('./ConfirmConsignment/ConfirmConsignmentModal'));
const ConfirmUnfollowSearchModal = loadable(() => import('./ConfirmUnfollowSearch/ConfirmUnfollowSearchModal'));
const DeleteAccountConfirmModal = loadable(() => import('./DeleteAccountConfirm/DeleteAccountConfirmModal'));
const ResendConfirmationEmailModal = loadable(() => import('./DeleteAccountConfirm/ResendConfirmationEmailModal'));
const DownloadMobileModal = loadable(() => import('./DownloadMobile/DownloadMobileModal'));
const LiveShippingDeadlineModal = loadable(() => import('./LiveShippingDeadline/LiveShippingDeadlineModal'));
const ExcludeHouseModal = loadable(() => import('./ExcludeHouse/ExcludeHouseModal'));
const FeedbackModal = loadable(() => import('./Feedback/FeedbackModal'));
const OnTheHuntModal = loadable(() => import('./OnTheHuntModal/OnTheHuntModal'));
const PhoneVerificationModal = loadable(() => import('./PhoneVerification/PhoneVerification'));
const PlaceAbsenteeBidModal = loadable(() => import('./AuthModal/AbsenteeBid/AuthAbsenteeBidModal'));
const RatingsAndReviewsModal = loadable(() => import('./RatingsAndReviews/RatingsAndReviewsModal'));
const RegisterForAuctionModal = loadable(() => import('./AuthModal/RegisterForAuction/AuthRegisterForAuctionModal'));
const ZipInfoModal = loadable(() => import('./ZipInfo/ZipInfoModal'));
const ReportReviewModal = loadable(() => import('./ReportReview/ReportReviewModal'));
const RetractBidModal = loadable(() => import('./RetractBid/RetractBidModal'));
const SendSellerConsignmentMessageModal = loadable(
    () => import('./SendSellerConsignmentMessage/SendSellerConsignmentMessageModal')
);
const SendSellerMessageModal = loadable(() => import('./SendSellerMessage/SendSellerMessageModal'));
const ShippingAndPaymentModal = loadable(() => import('./ShippingAndPayment/ShippingAndPaymentModal'));
const SMSConfirmationModal = loadable(() => import('./SMSConfirmation/SMSConfirmationModal'));
const TaxExemptModal = loadable(() => import('./TaxExempt/TaxExemptModal'));
const WaitingForApprovalModal = loadable(() => import('./WaitingForApproval/WaitingForApprovalModal'));
const ClearCacheModal = loadable(() => import('./ClearCache/ClearCache'));
const SendSellerPhotoRequestModal = loadable(() => import('./SendSellerPhotoRequest/SendSellerPhotoRequestModal'));
const GetHelpModal = loadable(() => import('./GetHelp/GetHelpModal'));

const Modals = () => {
    const { pathname } = useLocation();
    const openModal = useAppSelector(getOpenModal);
    const modalData = useAppSelector(getOpenModalData);

    // on the hunt checks
    const isBot = useAppSelector(getIsBot);
    const isOnTheHuntPage = getIsOnTheHuntPage(pathname) && !isBot;

    // tax exemption checks
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const isReseller = useAppSelector(getIsReseller);
    const isTaxExemptPage = isLoggedIn && isReseller;

    return (
        <>
            <AutoPopModalContainer route={pathname} />
            {isTaxExemptPage && <TaxExemptModal />}
            {isOnTheHuntPage && <OnTheHuntModal />}
            {openModal === modalTypes.ADD_CREDIT_CARD && <AddCreditCardModal />}
            {openModal === modalTypes.ADDRESS && <AddressModal data={modalData} />}
            {openModal === modalTypes.ADDRESS_VERIFY_MODAL && <AddressVerifyModal data={modalData} />}
            {openModal === modalTypes.BID_CONSOLE_EXPLAINER && <BidConsoleExplainer />}
            {openModal === modalTypes.BID_LIMIT_EXCEEDED && <BidLimitExceededModal />}
            {openModal === modalTypes.BUY_NOW && <BuyNowModal />}
            {openModal === modalTypes.CATALOG_ANNOUNCEMENT && <CatalogAnnouncmentModal />}
            {openModal === modalTypes.CHANGE_PASSWORD && <ChangePasswordModal />}
            {openModal === modalTypes.CLEAR_CACHE && <ClearCacheModal />}
            {openModal === modalTypes.COMPLETE_ACCOUNT_SIGNUP && <CompleteAccountSignupModal />}
            {openModal === modalTypes.CONFIRM_CONSIGNMENT && <ConfirmConsignmentModal />}
            {openModal === modalTypes.CONFIRM_UNFOLLOW_SEARCH && <ConfirmUnfollowSearchModal />}
            {openModal === modalTypes.DELETE_ACCOUNT_CONFIRMATION && <DeleteAccountConfirmModal />}
            {openModal === modalTypes.RESEND_CONFIRMATION_EMAIL && <ResendConfirmationEmailModal />}
            {openModal === modalTypes.DOWNLOAD_MOBILE && <DownloadMobileModal />}
            {openModal === modalTypes.EXCLUDE_HOUSE && <ExcludeHouseModal />}
            {openModal === modalTypes.FEEDBACK && <FeedbackModal />}
            {openModal === modalTypes.GET_HELP && <GetHelpModal />}
            {openModal === modalTypes.LIVE_SHIPPING_DEADLINE && <LiveShippingDeadlineModal />}
            {openModal === modalTypes.PHONE_VERIFICATION && <PhoneVerificationModal />}
            {openModal === modalTypes.PLACE_ABSENTEE_BID && <PlaceAbsenteeBidModal />}
            {openModal === modalTypes.PURCHASE_PROTECTION && <PurchaseProtectionModal />}
            {openModal === modalTypes.REGISTER_FOR_AUCTION && <RegisterForAuctionModal />}
            {openModal === modalTypes.REPORT_REVIEW && <ReportReviewModal />}
            {openModal === modalTypes.RETRACT_BID && <RetractBidModal />}
            {openModal === modalTypes.REVIEWS && <RatingsAndReviewsModal />}
            {openModal === modalTypes.SEND_SELLER_CONSIGNMENT_MESSAGE && <SendSellerConsignmentMessageModal />}
            {openModal === modalTypes.SEND_SELLER_MESSAGE && <SendSellerMessageModal />}
            {openModal === modalTypes.SEND_SELLER_PHOTO_REQUEST && <SendSellerPhotoRequestModal />}
            {openModal === modalTypes.SHIPPING_AND_PAYMENT && <ShippingAndPaymentModal />}
            {openModal === modalTypes.SMS_CONFIRMATION && <SMSConfirmationModal />}
            {openModal === modalTypes.WAITING_FOR_APPROVAL && <WaitingForApprovalModal />}
            {openModal === modalTypes.ZIP_INFO_MODAL && <ZipInfoModal />}
        </>
    );
};

export default React.memo(Modals);
