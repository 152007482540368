import { BodyPrimary, H2 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { changeActiveVariants, resetVariants } from '@/redux/modules/kameleoon/kameleoon.actions';
import { getActiveVariants, getUserSelectedVariants } from '@/redux/modules/kameleoon/kameleoon.selectors';
import { getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getVisitorCodeOrBidderId } from '@/utils/kameleoon/getVisitorCodeOrBidderId';
import {
    KameleoonExperimentIds,
    KameleoonExperiments,
    KameleoonVariants,
} from '@/hooks/kameleoon/kameleoonExperiments';
import { OptionType, Select } from '@liveauctioneers/hammer-ui-core/select';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useKameleoon } from '@/hooks/kameleoon/useKameleoon';
import { useState } from 'react';

const FeatureFlags = () => {
    const dispatch = useAppDispatch();

    const activeVariants = useAppSelector(getActiveVariants);
    const userSelectedVariants = useAppSelector(getUserSelectedVariants);
    const bidderId = useAppSelector(getBidderId);

    const [variants, setVariants] = useState(userSelectedVariants);
    const { kameleoonClient, visitorCode } = useKameleoon();
    const visitorCodeOrBidderId = getVisitorCodeOrBidderId({ bidderId, visitorCode });

    const onChange = (option: OptionType, key: KameleoonExperimentIds) => {
        const variations = kameleoonClient.getVariations({ visitorCode: visitorCodeOrBidderId });
        const experimentId = variations.get(key)?.experimentId;

        if (experimentId) {
            // Permanently change the variant for this user
            kameleoonClient?.setForcedVariation({
                experimentId,
                forceTargeting: false,
                variationKey: option.value,
                visitorCode: visitorCodeOrBidderId,
            });
        }

        setVariants({
            ...variants,
            [key]: option.value,
        });
    };

    const onApply = () => {
        dispatch(changeActiveVariants(variants));
    };
    const onReset = () => {
        setVariants(activeVariants);
        dispatch(resetVariants());
    };

    const variantSelect = (flagKey: KameleoonExperimentIds) => {
        const variantKey = KameleoonExperiments[flagKey];
        const variantOptions = KameleoonVariants[flagKey];

        const options = Object.keys(variantOptions).map((key) => ({
            label: key,
            value: variantOptions[key],
        }));

        const defaultValue = Boolean(variants[variantKey])
            ? options.find((option) => option.value === variants[variantKey])
            : { label: 'Off', value: 'off' };

        return (
            <Select
                defaultValue={defaultValue}
                onChange={(e) => onChange(e, variantKey)}
                options={options}
            />
        );
    };

    return (
        <section className="my-5 w-1/2">
            <H2 className="mb-5">Feature Flags</H2>
            {Object.keys(KameleoonExperiments).map((flagKey: KameleoonExperimentIds) => (
                <article
                    className="mb-5"
                    key={flagKey}
                >
                    <section className="mb-2 flex items-center">
                        <BodyPrimary>{flagKey}</BodyPrimary>
                    </section>
                    {variantSelect(flagKey)}
                </article>
            ))}
            <section className="mt-3 flex gap-4">
                <Button onClick={onApply}>APPLY</Button>
                <Button onClick={onReset}>RESET</Button>
            </section>
        </section>
    );
};

export default FeatureFlags;
